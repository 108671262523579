import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"
import customAxios from "../components/api/customaxios";


export function News() {
    const location = useLocation();
    const checkAdmin = location.pathname.startsWith("/admin") ? true : false
    const [newsId, setNewsId] = useState(0)
    const [news, setNews] = useState([])



    useEffect(() => {
        customAxios.get('/kis-news/show')
            .then(res => {
                setNews(res.data)
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    return (
        <div className={"flex pl-[10%] pr-[5%] "
            + (checkAdmin ? 'w-[80vw]' : 'w-[100vw]')}
        >
            <div className="w-[70%] my-[8%]">
                <div className={"text-2xl font-bold mb-[5%] " + (checkAdmin ? 'flex' : '')}>
                    <div className="max-w-[70%]">
                        {news[newsId]?.title}
                    </div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: news[newsId]?.content }} />
            </div>
            <div className="w-[30%] px-3 py-[8%]">
                <div className="border-2 border-l-[#094276] border-t-white border-r-white border-b-white ">
                    <div className="mx-2 text-lg mt-[10%]">List of news</div> <hr className="mb-3" />
                    <ul>
                        {news.map((item, index) => {
                            return (
                                // <div></div>
                                <li className="mx-2 font-light" key={item?.id} onClick={() => {
                                    setNewsId(index)
                                }}>
                                    <u>
                                        {item?.title.length > 20 ? item?.title.substring(0, 20) + '...' : item?.title}
                                    </u>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}