import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import React from "react"
import { Chrono } from "react-chrono";
import './about.css'

import HistoryImgBg from '../../assets/about/history.png'
import Img2022 from '../../assets/about/2022.png'
import Img2018_1 from '../../assets/about/2018(1).png'
import Img2018 from '../../assets/about/2018.png'
import Img012018 from '../../assets/about/012018.png'
import Creative from '../../assets/about/creative.png'
import ChangeName from '../../assets/about/changename.png'
import KisJp from '../../assets/about/estakisjp.png'
import { Vision } from './Vision';
import { useTranslation } from 'react-i18next';
// import Img2022 from '../../assets/about/2022.png'

const items = [
    {
        time: 'September, 2022',
        content: 'Change company name of KIS Japan to "Laidon-KIS Inc". Alliance with Trueson company',
        img: [Img2022]
    },
    {
        time: 'April 2, 2018',
        content: 'Partnership with Naganuma to train human force.',
        img: [Img2018]
    },
    {
        time: 'January 2, 2018',
        content: 'Joint venture with Logigear and the establishment of KIS-HCM Office.',
        img: [Img012018]
    },
    {
        time: 'March 27, 2017',
        content: 'Established AVR Creative Solution Vietnam JSC.',
        img: [Creative]
    },
    {
        time: 'March 3, 2017',
        content: 'Changed Company name to "KIS Vietnam Creative Co., Ltd."',
        img: [ChangeName]
    },
    {
        time: 'October 3, 2016',
        content: 'Established KIS Japan Co., Ltd.',
        img: [KisJp]
    },
    {
        time: 'June 17, 2013',
        content: 'Moved to new office at Technosoft Building, Hanoi.',
        img: [Img2018_1]
    },
    {
        time: 'August 8, 2012',
        content: 'Established LZT Vietnam Ltd. by associated with Ritatsu Soft Inc.',
        img: []
    },

]

export function History() {

    const items1 = []
    const {t} = useTranslation()

    for(const item of items){
        const newItem = {
            cardTitle: item.time,
            cardDetailedText: item.content,
            media: {
                type: 'IMAGE',
                source: {
                    url: item.img[0]
                }
            }
        }
        items1.push(newItem)
    }

    return (
        <div className='p-[10%]' style={{ backgroundImage: `url(${HistoryImgBg})` }}>
            <div className='text-center  mb-[15%] sm:my-[3vw] text-xl sm:text-3xl font-extrabold uppercase'
                style={{ fontFamily: 'Playfair Display' }}>{t('about.ourProfile')}</div>
            <div className="grid justify-items-center" >
                {window.innerWidth > 500 &&
                    <Timeline position="alternate" className="w-[100%]">
                        <TimelineItem>
                            <TimelineOppositeContent>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot sx={{ background: 'none' }}>
                                    <KeyboardArrowUpIcon sx={{ color: '#094276' }} />
                                </TimelineDot>
                                <TimelineConnector sx={{ bgcolor: '#094276' }} />
                            </TimelineSeparator>
                            <TimelineContent>
                            </TimelineContent>
                        </TimelineItem>
                        {items.map((item, index) => (
                            <TimelineItem>
                                <TimelineOppositeContent
                                    sx={{ m: 'auto 0' }}
                                    color="#094276"
                                >
                                    <div className={index % 2 === 0 ? "grid justify-items-start mx-[5vw]" : "grid justify-items-end mx-[6vw]"}>
                                        <div className='flex'>
                                            {item.img.map((itemimg, index) => (
                                                <div>
                                                    <img src={itemimg} key={index} alt="" className="max-h-[80px] sm:max-w-[120px] ml-[1vw]" />
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineConnector sx={{ bgcolor: '#094276' }} />
                                    <TimelineDot sx={{ bgcolor: '#094276' }}>
                                    </TimelineDot>
                                    <TimelineConnector sx={{ bgcolor: '#094276' }} />
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                    <div className="bg-white rounded-xl mx-[3vw] py-[1vw] px-[2vw]"
                                        style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                                        <div className="font-extrabold"
                                            style={{ fontFamily: 'Playfair Display' }}>{item.time}</div>
                                        <div className="text-sm">{item.content}</div>
                                    </div>
                                </TimelineContent>
                            </TimelineItem>
                        ))}
                    </Timeline>
                }
                {window.innerWidth < 500 &&
                    <div style={{ width: '100%' }}>
                        <Chrono
                            items={items1}
                            mode="VERTICAL"
                            theme={{
                                primary: '#094276',
                                secondary: '#094276',
                              }}
                            classNames={{
                                card: 'my-card',
                                cardMedia: 'my-card-media',
                                cardSubTitle: 'my-card-subtitle',
                                cardText: 'my-card-text',
                                cardTitle: 'my-card-title',
                                controls: 'my-controls',
                                title: 'my-title',
                              }}
                        />
                    </div>
                }
            </div>
            <Vision />
        </div>
    )
}