import { Hero } from "../components/about/Hero";
import { History } from "../components/about/History";
import { Offices } from "../components/about/Offices";
import '../components/about/about.css'
import React from "react";
import WholeCom from '../assets/about/wholecom.png'


export function About() {
    return (
        <div>
            <Hero />
            <Offices />
            <History />
            <img src={WholeCom} alt="whole com" className="w-[100%] mb-[10%]" />
        </div>
    )
}