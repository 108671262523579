import { useTranslation } from 'react-i18next'
import HeroImg from '../../assets/services/servicehero.png'

export function ServiceHero() {
    const {t} = useTranslation()
    return (
        <div className='bg-bottom bg-cover min-h-[50vh] sm:min-h-[37.08vw]' style={{ backgroundImage: `url(${HeroImg})` }}>
            <div className=' ml-[5%] sm:ml[10%] text-white w-[37%]'>
                <div className='text-xl sm:text-[3vw] font-extrabold py-[10vw] sm:pt-[11vw]'>{t('services.ourServices')}</div>
                <div className='text-[10px] leading-3 sm:leading-6 sm:text-[1.1vw]'>
                    {t('services.context')}
                </div>
            </div>
        </div>
    )
}