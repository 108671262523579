import { useState } from "react";
import { checkJapaneseWithParam } from "../../../utils";
import ReactQuill from "react-quill";
import customAxios from "../../../components/api/customaxios";

const styles = {
    reactQuill: {
        fontFamily: 'Lexend Deca'
    },
    save: {
        // back
    }
};

export function EditProject(props) {
    const lang = props.lang
    const setOpen = props.setOpen
    const project = props.project
    const setProject = props.setProject
    const setReload = props.setReload
    const projectsList = props.projectsList
    const setProjectsList = props.setProjectsList
    const projectIndex = props.projectIndex
    const [imgFile, setImgFile] = useState(null)
    const [controlEdit, setControlEdit] = useState({
        editContent: false,
        editDevLang: false,
        editTeamStructure: false,
        editCommunication: false,
        editDevProcess: false,
        editManageTool: false,
        editSecurity: false
    })
    const turnoffEdit = {
        editContent: false,
        editDevLang: false,
        editTeamStructure: false,
        editCommunication: false,
        editDevProcess: false,
        editManageTool: false,
        editSecurity: false
    }
    const [err, setErr] = useState({
        message: '',
        status: 0
    })

    const onFileChange = e => {
        console.log(projectIndex);
        setImgFile(e.target.files[0])
    }

    const onSave = () => {
        customAxios.put(`/projects/${project.id}`, { ...project, imgFile: imgFile }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {
                setErr(res.data)
            })
            .catch(e => console.log('onSave2', e))
        setReload(true)
    }


    const onDelete = () => {
        customAxios.delete(`/projects/${project.id}`)
            .then(res => setErr(res.data))
            .catch(e => console.log(e))
        setReload(true)
        setOpen(false)
        setProject(projectsList[0])
    }

    const onCancel = () => {
        setReload(true)
        setOpen(false)
    }

    const handleProjectChange = (propertyName, value) => {
        const projectsListChange = [...projectsList]
        const realPropertyName = checkJapaneseWithParam(lang) ? propertyName + 'Jp': propertyName;
        const nProject = { ...project, [realPropertyName]: value }
        setProject(nProject)
        console.log(nProject);
        projectsListChange[projectIndex] = nProject
        setProjectsList(projectsListChange)
    }

    const handleTitleChange = e => {
        handleProjectChange('title', e.target.value)
    }

    const handleContentChange = newContent => {
        handleProjectChange('content', newContent)
    }

    const handleDevLangChange = newContent => {
        handleProjectChange('devLanguage', newContent)
        console.log('project.devLanguageJp', project.devLanguageJp);
    }

    return (
        <>
            <div style={{ textTransform: 'uppercase', fontWeight: 800 }}>Edit project {parseInt(projectIndex) + 1} {lang}</div>
            <div style={{ margin: '5px 0' }}>
                <span>Title:</span>
                <input
                    type="text"
                    style={{ width: '20vw' }}
                    value={!checkJapaneseWithParam(lang) ? project.title : project.titleJp}
                    onChange={handleTitleChange}
                />
            </div>
            <div>
                Content:&nbsp;
                <button onClick={() => {
                    setControlEdit({ ...turnoffEdit, editContent: true })
                }}>
                    Edit
                </button>
            </div>
            {(controlEdit.editContent === true) && (
                <ReactQuill theme="snow"
                    // modules={modules}
                    value={checkJapaneseWithParam(lang) ? project.contentJp : project.content}
                    onChange={handleContentChange}
                    style={styles.reactQuill}
                />
            )}
            <div>
                Development Language / Environment:&nbsp;
                <button onClick={() => {
                    setControlEdit({ ...turnoffEdit, editDevLang: true })
                }}>
                    Edit
                </button>
            </div>
            {(controlEdit.editDevLang) && (
                <ReactQuill theme="snow"
                    // modules={modules}
                    value={checkJapaneseWithParam(lang) ? project.devLanguageJp : project.devLanguage}
                    onChange={handleDevLangChange}
                    style={styles.reactQuill}
                />
            )}
            <div>
                Development Scale /Team Structure:&nbsp;
                <button onClick={() => {
                    setControlEdit({ ...turnoffEdit, editTeamStructure: true })
                }}>
                    Edit
                </button>
            </div>
            {(controlEdit.editTeamStructure) && (
                <ReactQuill theme="snow"
                    // modules={modules}
                    value={checkJapaneseWithParam(lang) ? project.teamStructureJp : project.teamStructure}
                    onChange={newContent => {
                        // if (newContent === currentContent) return;
                        var projectsChange = projectsList
                        projectsChange.pop()
                        if (checkJapaneseWithParam(lang)) {
                            setProject({ ...project, teamStructureJp: newContent })
                        } else {
                            setProject({ ...project, teamStructure: newContent })
                        }
                    }}
                    style={styles.reactQuill}
                />
            )}
            <div>
                Communication:&nbsp;
                <button onClick={() => {
                    setControlEdit({ ...turnoffEdit, editCommunication: true })
                }}>
                    Edit
                </button>
            </div>
            {(controlEdit.editCommunication) && (
                <ReactQuill theme="snow"
                    // modules={modules}
                    value={checkJapaneseWithParam(lang) ? project.communicationJp : project.communication}
                    onChange={newContent => {
                        // if (newContent === currentContent) return;
                        var projectsChange = projectsList
                        projectsChange.pop()
                        if (checkJapaneseWithParam(lang)) {
                            setProject({ ...project, communicationJp: newContent })
                        } else {
                            setProject({ ...project, communication: newContent })
                        }
                    }}
                    style={styles.reactQuill}
                />
            )}

            <div>
                Development Process:&nbsp;
                <button onClick={() => {
                    setControlEdit({ ...turnoffEdit, editDevProcess: true })
                }}>
                    Edit
                </button>
            </div>
            {(controlEdit.editDevProcess) && (
                <ReactQuill theme="snow"
                    // modules={modules}
                    value={checkJapaneseWithParam(lang) ? project.devProcessJp : project.devProcess}
                    onChange={newContent => {
                        if (checkJapaneseWithParam(lang)) {
                            setProject({ ...project, devProcessJp: newContent })
                        } else {
                            setProject({ ...project, devProcess: newContent })
                        }
                    }}
                    style={styles.reactQuill}
                />
            )}

            <div>
                Management Tool:&nbsp;
                <button onClick={() => {
                    setControlEdit({ ...turnoffEdit, editManageTool: true })
                }}>
                    Edit
                </button>
            </div>
            {(controlEdit.editManageTool) && (
                <ReactQuill theme="snow"
                    // modules={modules}
                    value={checkJapaneseWithParam(lang) ? project.manageToolJp : project.manageTool}
                    onChange={newContent => {
                        if (checkJapaneseWithParam(lang)) {
                            setProject({ ...project, manageToolJp: newContent })
                        } else {
                            setProject({ ...project, manageTool: newContent })
                        }
                    }}
                    style={styles.reactQuill}
                />
            )}
            <div>
                Security        :&nbsp;
                <button onClick={() => {
                    setControlEdit({ ...turnoffEdit, editSecurity: true })
                }}>
                    Edit
                </button>
            </div>
            {(controlEdit.editSecurity) && (
                <ReactQuill theme="snow"
                    // modules={modules}
                    value={checkJapaneseWithParam(lang) ? project.securityJp : project.security}
                    onChange={newContent => {
                        if (checkJapaneseWithParam(lang)) {
                            setProject({ ...project, securityJp: newContent })
                        } else {
                            setProject({ ...project, security: newContent })
                        }
                    }}
                    style={styles.reactQuill}
                />
            )}
            <div>Image: {project.img}</div>
            <input type="file" onChange={onFileChange} />
            <div>Created At: {project.createdAt}</div>
            <div>Updated At: {project.updatedAt}</div>
            <button
                style={{
                    backgroundColor: '#094276',
                    color: 'white',
                    margin: 10
                }}
                onClick={() => onSave()}>
                Save
            </button>
            <button
                style={{
                    backgroundColor: 'red',
                    color: 'white',
                    margin: 10
                }}
                onClick={() => onDelete()}>
                {'Delete'}
            </button>
            <button
                style={{
                    backgroundColor: '#a16207',
                    color: 'white',
                    margin: 10
                }}
                onClick={onCancel}>
                {'Cancel'}
            </button>
            <div className={err.status !== 200 ? 'text-red-500' : 'text-green-500'}>{err.message}</div>
        </>
    )
}