
import { Swiper, SwiperSlide } from "swiper/react";
import React from "react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/pagination';
import Slide1 from '../../assets/home/slide1.png'
// import Slide2 from '../../assets/home/slide2.jpg'
import Slide3 from '../../assets/home/slide3.png'


// import required modules
import { Navigation, Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";
export function Slider() {
    const { t } = useTranslation();

    return (
        <Swiper
            navigation={true}
            pagination={true}
            modules={[Navigation, Pagination]}
            className="mySwiper"
        >
            <SwiperSlide className="bg-bottom bg-cover min-h-[50vh] sm:min-h-[37.08vw]" style={{ backgroundImage: `url(${Slide1})` }}>
                <div className="grid justify-items-center">
                    <div className="font-extrabold text-lg w-[90%] sm:w-[80%] sm:text-[2.5vw] text-center mt-[3vw] sm:mb-[3vw]">
                        {t("home.companyName")}
                    </div>
                    <div className="font-medium text-center w-[75%] sm:w-[55%] text-xs sm:text-[1.3vw] sm:leading-[1.5vw]">
                        {t("home.slide1Content")}
                    </div>
                    <a className="rounded-full bg-[#296597] text-white px-[1.5vw] py-[0.7vw] uppercase font-extrabold mt-[4.5vw] 
                    shadow-md text-xs sm:text-[1vw]"
                        href="#services-home">
                        {t("home.getStarted")}
                    </a>
                </div>
            </SwiperSlide>
            <SwiperSlide className="bg-bottom bg-cover min-h-[50vh] sm:min-h-[37.08vw]" style={{ backgroundImage: `url(${Slide1})` }}>
                <div className="grid justify-items-center">
                    <div className="text-center w-[70%] sm:w-[55%] mt-[5vw] text-[#296597] text-xs sm:text-[1.3vw] sm:leading-[1.5vw]"
                    dangerouslySetInnerHTML={{__html: t('home.slider2Content')}}>
                         </div>
                </div>
            </SwiperSlide>
            <SwiperSlide className="bg-bottom bg-cover min-h-[50vh] sm:min-h-[37.08vw]" style={{ backgroundImage: `url(${Slide3})` }}>
                <div className="sm:w-[60%] mt-[5%] sm:mt-[3vw] ml-[5%] mr-[5%] font-medium text-[11px] leading-3 sm:text-[1.3vw] sm:leading-[1.5vw]"
                dangerouslySetInnerHTML={{__html: t('home.slider3Content')}}>
                    </div>
            </SwiperSlide>
        </Swiper>
    )
}