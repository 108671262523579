
import React, { useEffect, useState } from 'react'
import customAxios from '../api/customaxios'
import { checkiflessthan10, showValueBasedOnLang } from '../../utils'


export function Project() {

    const [projects, setProjects] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const lang = localStorage.getItem('i18nextLng')
    const [imgList, setImgList] = useState([])

    useEffect(() => {
        customAxios.get('/projects')
            .then(res => {
                setProjects(res.data)
                setIsLoading(false)
            })
            .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        if (projects === undefined) return
        projects.forEach((projectItem, index) => {
            if (projectItem.img !== null) {
                customAxios.get('/projects/img', {
                    responseType: 'blob',
                    params: {
                        img: projectItem.img
                    }
                })
                    .then(res => {
                        const addImgList = [...imgList]
                        addImgList.push(URL.createObjectURL(res.data))
                        setImgList([...addImgList])
                    })
                    .catch(err => console.log(err))
            }
        })
        console.log('projects', projects);
    }, [projects])

    return isLoading ? (
        <>Loading, please wait...</>
    ) : (
        <div className="m-[10%]">
            {projects.map((projectItem, index) => {
                return (
                    <div key={index} className="rounded-2xl p-[5%] my-[5%]" style={{ boxShadow: '4px 4px 29px 6px rgba(0, 0, 0, 0.25) inset' }}>
                        <div className="flex flex-wrap w-[100%]">
                            <div className="sm:w-[55%]">
                                <p className="rounded-2xl w-fit text-white bg-[#094276] tracking-widest px-5 py-2 font-bold"
                                    style={{ boxShadow: '0px 0px 7px 3px rgba(255, 255, 255, 0.25) inset', border: '1px #85C5FF solid' }}>
                                    {checkiflessthan10(parseInt(index) + 1)} <span className="text-[#3F6E93]">/ {checkiflessthan10(projects.length)}</span>
                                </p>
                            </div>
                            <div className="text-left my-[5%] sm:my-0 sm:text-right sm:w-[45%] text-xs sm:text-base">
                                <p>
                                    {localStorage.getItem('i18nextLng') !== 'jp' ?
                                        <>
                                            These are some of <span className='uppercase font-black text-base sm:text-lg sm:text-[1.3vw]'>outstanding projects</span>.
                                        </>
                                        :
                                        <>
                                            これらはいくつかの<span className='uppercase font-black text-base sm:text-lg sm:text-[1.3vw]'>典型的なプロジェクト</span>です.
                                        </>}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-wrap">
                            <div className={projectItem.img ? "sm:w-[55%]" : 'sm:w-[100%]'}>

                                <p className="text-base sm:text-2xl sm:text-[2vw] font-extrabold my-[3%]" style={{ fontFamily: 'Playfair Display' }}>
                                    {showValueBasedOnLang(lang, projectItem, 'title')}
                                </p>
                                <div className='text-xs sm:text-base' dangerouslySetInnerHTML={{ __html: showValueBasedOnLang(lang, projectItem, 'content') }} />
                            </div>
                            {projectItem.img &&
                                <div className="flex projects-end my-[10vw] sm:my-0 sm:w-[45%]">
                                    <div className='grid justify-projects-end w-[100%]'>
                                        <img
                                            src={imgList[index]}
                                            alt={projectItem.img} className='max-h-[20vwe]' />
                                    </div>
                                </div>
                            }

                        </div>
                        <div className='sm:table sm:border-spacing-3 border border-separate border-white text-[10px] leading-3 sm:text-base'>
                            <div className="sm:table-header-group">
                                {projectItem.devLanguage && (
                                    <div className='sm:table-row'>
                                        <div className='sm:table-cell text-white bg-[#094276] rounded-lg sm:w-[30%] my-2 sm:my-[5vw] sm:my-0 uppercase font-bold px-1 py-1 sm:px-2 sm:py-1'>DEVELOPMENT LANGUAGE /ENVIRONMENT</div>
                                        <div className='sm:table-cell border border-[#094276] rounded-lg uppercase mb-5 sm:mb-[10vw] sm:mb-0 px-1 py-1 sm:px-2 sm:py-1'
                                            dangerouslySetInnerHTML={{ __html: showValueBasedOnLang(lang, projectItem, 'devLanguage') }}
                                        />
                                    </div>
                                )}
                                {projectItem.teamStructure && (
                                    <div className='sm:table-row'>
                                        <div className='sm:table-cell text-white bg-[#094276] rounded-lg sm:w-[30%] my-2 sm:my-[5vw] sm:my-0 uppercase font-bold px-1 py-1 sm:px-2 sm:py-1'>DEVELOPMENT SCALE /TEAM STRUCTURE</div>
                                        <div className='sm:table-cell border border-[#094276] rounded-lg uppercase mb-5 sm:mb-[10vw] sm:mb-0 px-1 py-1 sm:px-2 sm:py-1'
                                            dangerouslySetInnerHTML={{ __html: showValueBasedOnLang(lang, projectItem, 'teamStructure') }}
                                        />
                                    </div>
                                )}
                                {projectItem.security && (
                                    <div className='sm:table-row'>
                                        <div className='sm:table-cell text-white bg-[#094276] rounded-lg sm:w-[30%] my-2 sm:my-[5vw] sm:my-0 uppercase font-bold px-1 py-1 sm:px-2 sm:py-1'>SECURITY</div>
                                        <div className='sm:table-cell border border-[#094276] rounded-lg uppercase mb-5 sm:mb-[10vw] sm:mb-0 px-1 py-1 sm:px-2 sm:py-1'
                                            dangerouslySetInnerHTML={{ __html: showValueBasedOnLang(lang, projectItem, 'security') }}
                                        />
                                    </div>
                                )}
                                {projectItem.communication && (
                                    <div className='sm:table-row'>
                                        <div className='sm:table-cell text-white bg-[#094276] rounded-lg sm:w-[30%] my-2 sm:my-[5vw] sm:my-0 uppercase font-bold px-1 py-1 sm:px-2 sm:py-1'>COMMUNICATION</div>
                                        <div className='sm:table-cell border border-[#094276] rounded-lg uppercase mb-5 sm:mb-[10vw] sm:mb-0 px-1 py-1 sm:px-2 sm:py-1'
                                            dangerouslySetInnerHTML={{ __html: showValueBasedOnLang(lang, projectItem, 'communication') }}
                                        />
                                    </div>
                                )}
                                {projectItem.devProcess && (
                                    <div className='sm:table-row'>
                                        <div className='sm:table-cell text-white bg-[#094276] rounded-lg sm:w-[30%] my-2 sm:my-[5vw] sm:my-0 uppercase font-bold px-1 py-1 sm:px-2 sm:py-1'>DEVELOPMENT PROCESS</div>
                                        <div className='sm:table-cell border border-[#094276] rounded-lg uppercase mb-5 sm:mb-[10vw] sm:mb-0 px-1 py-1 sm:px-2 sm:py-1'
                                            dangerouslySetInnerHTML={{ __html: showValueBasedOnLang(lang, projectItem, 'devProcess') }}
                                        />
                                    </div>
                                )}
                                {projectItem.manageTool && (
                                    <div className='sm:table-row'>
                                        <div className='sm:table-cell text-white bg-[#094276] rounded-lg sm:w-[30%] my-2 sm:my-[5vw] sm:my-0 uppercase font-bold px-1 py-1 sm:px-2 sm:py-1'>MANAGEMENT TOOL</div>
                                        <div className='sm:table-cell border border-[#094276] rounded-lg uppercase mb-5 sm:mb-[10vw] sm:mb-0 px-1 py-1 sm:px-2 sm:py-1'
                                            dangerouslySetInnerHTML={{ __html: showValueBasedOnLang(lang, projectItem, 'manageTool') }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}