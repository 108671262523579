import { useState } from "react";
import ReactQuill from "react-quill";
import customAxios from "../../../../components/api/customaxios";

const styles = {
    reactQuill: {
        fontFamily: 'Lexend Deca'
    },
    save: {
        // back
    }
};

export function CreateProject(props) {
    const project = props.project
    const setProject = props.setProject
    const setReload = props.setReload
    const projectsList = props.projectsList
    const setProjectsList = props.setProjectsList
    const projectIndex = props.projectIndex
    const setOpenCreate = props.setOpenCreate
    const [err, setErr] = useState({
        message: '',
        status: 0
    })

    return (
        <>
            <div style={{ textTransform: 'uppercase', fontWeight: 800 }}>Create new project {parseInt(projectIndex) + 1}</div>
            <div style={{ margin: '5px 0' }}>
                <span>Title:</span> <br />
                <input
                    type="text"
                    style={{ width: '100%' }}
                    value={project.title}
                    onChange={e => {
                        var projectsChange = projectsList
                        projectsChange.pop()
                        setProject({ ...project, title: e.target.value })
                        projectsChange.push({ ...project, title: e.target.value })
                        setProjectsList(projectsChange)
                    }}
                />
            </div>
            <div>Content:</div>
            <ReactQuill theme="snow"
                // modules={modules}
                value={project.content}
                onChange={newContent => {
                    // if (newContent === currentContent) return;
                    var projectsChange = projectsList
                    projectsChange.pop()
                    setProject({ ...project, content: newContent })
                    projectsChange.push({ ...project, content: newContent })
                    setProjectsList(projectsChange)
                }}
                style={styles.reactQuill}
            />
            <div>Created At: {project.createdAt}</div>
            <div>Updated At: {project.updatedAt}</div>
            {err.status !== 200 && (
                <button
                    style={{
                        backgroundColor: '#094276',
                        color: 'white',
                        margin: 10
                    }}
                    onClick={() => {
                        customAxios.post(`/projects`, project)
                            .then(res => setErr(res.data))
                            .catch(e => console.log(e))
                        setReload(true)
                    }}>
                    Save
                </button>
            )}

            <button
                style={{
                    backgroundColor: 'red',
                    color: 'white',
                    margin: 10
                }}
                onClick={() => {
                    setReload(true)
                    setOpenCreate(false)
                }}>
                {err.status !== 200 ? 'Cancel' : 'Finish'}
            </button>
            <div className={err.status !== 200 ? 'text-red-500' : 'text-green-500'}>{err.message}</div>
        </>
    )
}