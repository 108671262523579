// axiosInstance.js
import axios from 'axios';


const customAxios = axios.create({
  baseURL: 'https://api.kis-v.com', // Your base URL
  headers: {
    'Content-Type': 'application/json'
    // You can add any other default headers here
  },
});

customAxios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

customAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default customAxios;
