import { useEffect } from "react";
import { useState } from "react";
import customAxios from "../../../../components/api/customaxios";
import { LANGUAGES, checkiflessthan10, showValueBasedOnLang } from "../../../../utils";
import React from 'react'


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { EditProject } from "../EditProject";
import { CreateProject } from "../CreateProject";


export function Projects(props) {
    const [isLoading, setIsLoading] = useState(true)
    const [projectsList, setProjectsList] = useState([])
    const [lang, setLang] = useState(LANGUAGES.EN)
    const [reload, setReload] = useState(false)
    const [projectIndex, setProjectIndex] = useState(0)
    const [project, setProject] = useState({
        id: undefined,
        title: '',
        titleJp: '',
        content: '',
        contentJp: '',
        devLanguage: '',
        devLanguageJp: '',
        teamStructure: '',
        teamStructureJp: '',
        communication: '',
        communicationJp: '',
        devProcess: '',
        devProcessJp: '',
        manageTool: '',
        manageToolJp: '',
        security: '',
        securityJp: '',
        img: ''
    })
    const [openEdit, setOpenEdit] = useState(false)
    const [openCreate, setOpenCreate] = useState(false)
    const [imgFile, setImgFile] = useState()

    useEffect(() => {
        customAxios.get('/projects')
            .then(res => {
                setProjectsList(res.data)
                setProject((res.data)[projectIndex])
                setIsLoading(false)
                setReload(false)
                console.log('reloaded');
            })
            .catch(err => {
                console.log(err);
            })
    }, [reload])

    useEffect(()=>{
        if (project === undefined) return
        customAxios.get('/projects/img', {
            responseType: 'blob',
            params: {
                img: project.img
            }
        })
            .then(res => {
                setImgFile(URL.createObjectURL(res.data))
            })
            .catch(err => console.log(err))
    }, [reload, project])

    return isLoading
        ? (
            <>Loading, please wait...</>
        )
        : (
            <div className="flex-auto">
                <div className="flex" style={{ width: '100%' }}>
                    <div className="flex-1">
                        {!openCreate && !openEdit && (
                            <div className="flex mb-10 border shadow-md w-fit p-2">
                                <span>Choose Project: </span>
                                {projectsList.length === 0 ? (
                                    <>No project</>
                                ) : (
                                    <select onChange={event => {
                                        setProject(projectsList[event.target.value])
                                        setProjectIndex(event.target.value)
                                        console.log('projectsList[event.target.value]', projectsList[event.target.value]);
                                    }}>
                                        <option defaultValue={true}>Choose</option>
                                        {projectsList.map((project, index) => (
                                            <option key={index} value={index}>{index + 1}</option>
                                        ))}
                                    </select>
                                )}
                                &nbsp;
                                <span>Choose Language: </span>
                                <select onChange={event => {
                                    setLang(event.target.value)
                                    setOpenEdit(false)
                                }}>
                                    <option>Choose</option>
                                    <option value={LANGUAGES.EN}>English</option>
                                    <option value={LANGUAGES.JP}>Japanese</option>
                                </select>&nbsp;
                                <button onClick={async () => {
                                    console.log('projectIndex', projectIndex);
                                    setOpenEdit(true)
                                }}>
                                    Choose
                                </button>
                            </div>
                        )}


                        {!openEdit && !openCreate && (
                            <div className="flex mb-10 border shadow-md w-fit p-2">

                                <button onClick={async () => {
                                    setOpenCreate(true)
                                    setProjectIndex(projectsList.length)
                                    const newProject = {
                                        title: '',
                                        content: ''
                                    }
                                    setProject(newProject)
                                    var addProjects = projectsList
                                    addProjects.push(newProject)
                                    setProjectsList(addProjects)
                                }}>
                                    Create new project
                                </button>
                                <div style={{ color: 'red', margin: '0 5px' }}>WARNING: This edit or create will also affect projects in outsourcing services part</div>
                            </div>
                        )}



                        {openEdit && (
                            <EditProject
                                lang={lang}
                                project={project}
                                setProject={setProject}
                                setReload={setReload}
                                setOpen={setOpenEdit}
                                projectsList={projectsList}
                                setProjectsList={setProjectsList}
                                projectIndex={projectIndex}
                            />
                        )}

                        {openCreate && (
                            <CreateProject
                                projectIndex={projectIndex}
                                project={project}
                                setProject={setProject}
                                projectsList={projectsList}
                                setProjectsList={setProjectsList}
                                setReload={setReload}
                                setOpenCreate={setOpenCreate}
                            />
                        )}
                    </div>
                    {(openCreate || openEdit) && (
                        <div className="flex-1 admin">
                            {projectsList.length > 0 && (
                                <div className="rounded-2xl p-[5%] my-[5%]" style={{ boxShadow: '4px 4px 29px 6px rgba(0, 0, 0, 0.25) inset' }}>
                                    <div className="flex flex-wrap w-[100%]">
                                        <div className="sm:w-[55%]">
                                            <p className="rounded-2xl w-fit text-white bg-[#094276] tracking-widest px-5 py-2 font-bold"
                                                style={{ boxShadow: '0px 0px 7px 3px rgba(255, 255, 255, 0.25) inset', border: '1px #85C5FF solid' }}>
                                                {checkiflessthan10(parseInt(projectIndex) + 1)} <span className="text-[#3F6E93]">/ {checkiflessthan10(projectsList.length)}</span>
                                            </p>
                                        </div>
                                        <div className="text-left my-[5%] sm:my-0 sm:text-right sm:w-[45%] text-xs sm:text-base">
                                            <p>These are some of <span className="uppercase font-bold text-base sm:text-lg sm:text-[1.3vw]">outstanding projects</span> .</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap">
                                        <div className={project.img ? "sm:w-[55%]" : 'sm:w-[100%]'}>

                                            <p className="text-base sm:text-2xl sm:text-[2vw] font-extrabold my-[3%]" style={{ fontFamily: 'Playfair Display' }}>
                                                {showValueBasedOnLang(lang, project, 'title')}
                                            </p>
                                            <div className='text-xs sm:text-base' dangerouslySetInnerHTML={{ __html: showValueBasedOnLang(lang, project, 'content') }} />
                                        </div>
                                        {project.img &&
                                            <div className="flex projects-end my-[10vw] sm:my-0 sm:w-[45%]">
                                                <div className='grid justify-projects-end w-[100%]'>
                                                    <img
                                                        src={imgFile}
                                                        alt="outsrc1" className='max-h-[20vwe]' />
                                                </div>
                                            </div>
                                        }

                                    </div>
                                    <div className='sm:table sm:border-spacing-3 border border-separate border-white text-[10px] leading-3 sm:text-base'>
                                        <div className="sm:table-header-group">
                                            {project.devLanguage && (
                                                <div className='sm:table-row'>
                                                    <div className='sm:table-cell text-white bg-[#094276] rounded-lg sm:w-[30%] my-2 sm:my-[5vw] sm:my-0 uppercase font-bold px-1 py-1 sm:px-2 sm:py-1'>DEVELOPMENT LANGUAGE /ENVIRONMENT</div>
                                                    <div className='sm:table-cell border border-[#094276] rounded-lg uppercase mb-5 sm:mb-[10vw] sm:mb-0 px-1 py-1 sm:px-2 sm:py-1'
                                                        dangerouslySetInnerHTML={{ __html: showValueBasedOnLang(lang, project, 'devLanguage') }}
                                                    />
                                                </div>
                                            )}
                                            {project.teamStructure && (
                                                <div className='sm:table-row'>
                                                    <div className='sm:table-cell text-white bg-[#094276] rounded-lg sm:w-[30%] my-2 sm:my-[5vw] sm:my-0 uppercase font-bold px-1 py-1 sm:px-2 sm:py-1'>DEVELOPMENT SCALE /TEAM STRUCTURE</div>
                                                    <div className='sm:table-cell border border-[#094276] rounded-lg uppercase mb-5 sm:mb-[10vw] sm:mb-0 px-1 py-1 sm:px-2 sm:py-1'
                                                        dangerouslySetInnerHTML={{ __html: showValueBasedOnLang(lang, project, 'teamStructure') }}
                                                    />
                                                </div>
                                            )}
                                            {project.security && (
                                                <div className='sm:table-row'>
                                                    <div className='sm:table-cell text-white bg-[#094276] rounded-lg sm:w-[30%] my-2 sm:my-[5vw] sm:my-0 uppercase font-bold px-1 py-1 sm:px-2 sm:py-1'>SECURITY</div>
                                                    <div className='sm:table-cell border border-[#094276] rounded-lg uppercase mb-5 sm:mb-[10vw] sm:mb-0 px-1 py-1 sm:px-2 sm:py-1'
                                                        dangerouslySetInnerHTML={{ __html: showValueBasedOnLang(lang, project, 'security') }}
                                                    />
                                                </div>
                                            )}
                                            {project.communication && (
                                                <div className='sm:table-row'>
                                                    <div className='sm:table-cell text-white bg-[#094276] rounded-lg sm:w-[30%] my-2 sm:my-[5vw] sm:my-0 uppercase font-bold px-1 py-1 sm:px-2 sm:py-1'>COMMUNICATION</div>
                                                    <div className='sm:table-cell border border-[#094276] rounded-lg uppercase mb-5 sm:mb-[10vw] sm:mb-0 px-1 py-1 sm:px-2 sm:py-1'
                                                        dangerouslySetInnerHTML={{ __html: showValueBasedOnLang(lang, project, 'communication') }}
                                                    />
                                                </div>
                                            )}
                                            {project.devProcess && (
                                                <div className='sm:table-row'>
                                                    <div className='sm:table-cell text-white bg-[#094276] rounded-lg sm:w-[30%] my-2 sm:my-[5vw] sm:my-0 uppercase font-bold px-1 py-1 sm:px-2 sm:py-1'>DEVELOPMENT PROCESS</div>
                                                    <div className='sm:table-cell border border-[#094276] rounded-lg uppercase mb-5 sm:mb-[10vw] sm:mb-0 px-1 py-1 sm:px-2 sm:py-1'
                                                        dangerouslySetInnerHTML={{ __html: showValueBasedOnLang(lang, project, 'devProcess') }}
                                                    />
                                                </div>
                                            )}
                                            {project.manageTool && (
                                                <div className='sm:table-row'>
                                                    <div className='sm:table-cell text-white bg-[#094276] rounded-lg sm:w-[30%] my-2 sm:my-[5vw] sm:my-0 uppercase font-bold px-1 py-1 sm:px-2 sm:py-1'>MANAGEMENT TOOL</div>
                                                    <div className='sm:table-cell border border-[#094276] rounded-lg uppercase mb-5 sm:mb-[10vw] sm:mb-0 px-1 py-1 sm:px-2 sm:py-1'
                                                        dangerouslySetInnerHTML={{ __html: showValueBasedOnLang(lang, project, 'manageTool') }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    )}

                </div>
            </div>
        )
}