import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './home.css'

import { Pagination, Navigation } from "swiper/modules";
import { useTranslation } from 'react-i18next';
import customAxios from '../api/customaxios';

export function Customer() {
    const { t } = useTranslation()
    const [customers, setCustomers] = React.useState([])

    useEffect(() => {
        customAxios.get('/customers/show')
        .then(res => {
            setCustomers(res.data)
        })
        .catch(err => {
            console.log(err);
        })  
    },[])
    
    return (
        <div className='customer w-[80%] mx-[10%]'>
            <div className='text-center font-extrabold text-xl sm:text-3xl my-[1vw]' style={{ fontFamily: 'Playfair Display' }}>{t('home.ourCustomers')}</div>
            <div className='text-center text-xs sm:text-base my-[3vw]' >
                <div style={{ maxWidth: '60%', margin: '0 20%' }}>
                    {t('home.inAllSincerity')}
                </div>

            </div>
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                // loop={true}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                breakpoints={{
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 50,
                    },
                }}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
                {customers.map((item, index) => (
                    <SwiperSlide key={index}>
                        <img src={`data:image/${item.imgType};base64,${item.imgData}`} 
                        key={index} 
                        alt={item.name} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}