export const LANGUAGES = {
  EN: "en",
  JP: "jp",
};

export const checkJapaneseWithParam = (lang) => LANGUAGES.JP === lang;

export const checkJapaneseInStorage = () => localStorage.getItem('i18nextLng') === "jp";

export function checkiflessthan10(i) {
  return i < 10 ? "0" + i : i;
}

export const showValueBasedOnLang = (lang, object, propertyName) => {
  return object[propertyName + (checkJapaneseWithParam(lang) ? 'Jp' : '')]
};
