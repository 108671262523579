import { useEffect } from "react";
import { useState } from "react";
import customAxios from "../../../components/api/customaxios";
import '../../../components/home/home.css'
import { LANGUAGES } from "../../../utils";
import React from 'react'
import { EditOffice } from "./EditOffice";


export function OfficesManagement(props) {
    const [isLoading, setIsLoading] = useState(true)
    const [offices, setOffices] = useState([])
    const [lang, setLang] = useState(LANGUAGES.EN)
    const [reload, setReload] = useState(false)
    const [office, setOffice] = useState({
        id: -1,
        name: "",
        nameJp: "",
        companyName: "",
        companyNameJp: "",
        established: "",
        establishedJp: "",
        president: "",
        presidentJp: "",
        registerCapital: "",
        registerCapitalJp: "",
        place: "",
        placeJp: "",
        phone: "",
        website: "",
        email: "",
        createdAt: '',
        updatedAt: ''
    })
    const [editOffice, setEditOffice] = useState(false)

    function checkisJapan() {
        return lang === LANGUAGES.JP
    }

    useEffect(() => {
        customAxios.get('/office')
            .then(res => {
                setOffices(res.data)
                setOffice((res.data)[0])
                setIsLoading(false)
                setReload(false)
            })
            .catch(err => {
                console.log(err);
            })
    }, [reload])

    return isLoading
        ? (
            <>Loading, please wait...</>
        )
        : (
            <div className="flex-auto">
                <div className="flex" style={{ width: '100%' }}>
                    <div className="flex-1">
                        <div className="flex mb-10 border shadow-md w-fit p-2">
                            <span>Choose Offices: </span>
                            <select onChange={event => {
                                setOffice(offices[event.target.value])
                                setEditOffice(false)
                            }}>
                                {offices.map((item, index) => (
                                    <option key={index} value={index}>{index + 1}</option>
                                ))}
                            </select>&nbsp;
                            <span>Choose Language: </span>
                            <select onChange={event => {
                                setLang(event.target.value)
                                setEditOffice(false)
                            }}>
                                <option value={LANGUAGES.EN}>English</option>
                                <option value={LANGUAGES.JP}>Japanese</option>
                            </select>&nbsp;
                            <button onClick={() => {
                                setEditOffice(true)
                            }}>
                                Choose
                            </button>
                        </div>
                        {editOffice && (
                            <EditOffice
                                lang={lang}
                                office={office}
                                setOffice={setOffice}
                                setReload={setReload}
                                setEditOffice={setEditOffice}
                            />)}
                    </div>
                    {editOffice && (
                        <div className='p-[5%] flex-1 shadow-lg m-2'>
                            <div className='font-extrabold text-lg sm:text-[1.6vw]' style={{ fontFamily: 'Playfair Display' }}>
                                Our Offices
                            </div>
                            <div className='sm:text-[1.3vw] text-sm font-semibold my-[5%]'>
                                {checkisJapan() ? office?.nameJp : office?.name}
                            </div>
                            <div className='sm:table border-spacing-1 border border-separate border-white text-xs sm:text-[0.9vw]'>
                                <div className='sm:table-row sm:mb-0 mb-[8vw]-group'>
                                    <div className='sm:table-row sm:mb-0 mb-[8vw]'>
                                        <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white'>Company Name</div>
                                        <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white font-light'>
                                            {checkisJapan() ? office?.companyNameJp : office?.companyName}
                                        </div>
                                    </div>
                                    <div className='sm:table-row sm:mb-0 mb-[8vw]'>
                                        <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white '>Established Day</div>
                                        <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white font-light'>
                                            {checkisJapan() ? office?.establishedJp : office?.established}
                                        </div>
                                    </div>
                                    <div className='sm:table-row sm:mb-0 mb-[8vw]'>
                                        <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white flex items-start '>President/CEO</div>
                                        <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white font-light'>
                                            <div dangerouslySetInnerHTML={{ __html: checkisJapan() ? office?.presidentJp : office?.president }} />
                                        </div>
                                    </div>
                                    <div className='sm:table-row sm:mb-0 mb-[8vw]'>
                                        <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white flex items-start '>Registered Capital</div>
                                        <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white font-light'>
                                            {checkisJapan() ? office?.registerCapitalJp : office?.registerCapital}
                                        </div>
                                    </div>
                                    <div className='sm:table-row sm:mb-0 mb-[8vw]'>
                                        <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white flex items-start '>Place</div>
                                        <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white font-light'>
                                            <div dangerouslySetInnerHTML={{ __html: checkisJapan() ? office?.placeJp : office?.place }} />
                                        </div>
                                    </div>
                                    <div className='sm:table-row sm:mb-0 mb-[8vw]'>
                                        <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white flex items-start '>Phone</div>
                                        <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white font-light'>
                                            {office?.phone}
                                        </div>
                                    </div>
                                    <div className='sm:table-row sm:mb-0 mb-[8vw]'>
                                        <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white flex items-start '>Website</div>
                                        <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white font-light'>
                                            {office?.website}
                                        </div>
                                    </div>
                                    <div className='sm:table-row sm:mb-0 mb-[8vw]'>
                                        <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white flex items-start '>Email</div>
                                        <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white font-light'>
                                            {office?.email}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        )
}