import { Customer } from "../components/home/Customer";
import { Project } from "../components/home/Project";
import { Services } from "../components/home/Services";
import { Slider } from "../components/home/Slider";
import GetInTouch from '../components/home/GetInTouch'
import React from 'react'

export function Home({targetDivRef}) {
    return (
        <div>
            <Slider />
            <Services />
            <Project />
            <Customer />
            <GetInTouch ref={targetDivRef}/>
        </div>
    )
}