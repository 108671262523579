import { useState } from "react"
import React from 'react'
import { CustomersManagement } from "./customer/CustomersManagement"
import {Projects} from './project/Project'

const styles = {
    button: {
        margin: 10,
        padding: 10,
        color: '#094276',
        borderRadius: '1rem'
    }
}

export function HomeManagement() {
    const [openProject, setOpenProject] = useState(false)
    const [openCustomer, setOpenCustomer] = useState(false)

    return (
        <>
            <div className="p-10 w-[80vw]">
                <div style={{ width: '100%' }}>
                    <button
                        style={{
                            ...styles.button,
                            backgroundColor: (!openProject ? 'rgb(243 244 246)' : 'rgb(209 213 219)')
                        }}
                        onClick={() => {
                            setOpenProject(true)
                            setOpenCustomer(false)
                        }}
                    >
                        Projects Management
                    </button>
                    <button
                        style={{
                            ...styles.button,
                            backgroundColor: (!openCustomer ? 'rgb(243 244 246)' : 'rgb(209 213 219)')
                        }}
                        onClick={() => {
                            setOpenProject(false)
                            setOpenCustomer(true)
                        }}
                    >
                        Customers Management
                    </button>
                </div>
                {openCustomer && <CustomersManagement />}
                {openProject && <Projects />}
            </div>

        </>
    )
}