import Boss from '../../assets/about/boss.png'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { useTranslation } from 'react-i18next';

export function Vision() {
    const { t } = useTranslation()
    return (
        <div className="flex flex-wrap mt-[10%]">
            <div className='flex items-center sm:my-0 mt-[10vw]'>
                <img src={Boss} alt="Boss" className='rounded-2xl sm:w-[30vw]' style={{ boxShadow: '0px 4px 50px rgba(179.56, 179.56, 179.56, 0.25)' }} />
            </div>
            <div className='sm:ml-[5vw] sm:w-[55%] w-[100%] min-h-[40vw]'>
                <Swiper navigation={true} modules={[Navigation]} className="mySwiper h-[100%]">
                    <SwiperSlide>
                        <div className='rounded-2xl bg-white h-[100%] p-[10%]'>
                            <div className='font-extrabold text-2xl' style={{ fontFamily: 'Playfair Display' }}>{t('about.intro')}</div>
                            <div className='mt-[5%] text-[12px] leading-4 sm:text-sm'>
                                <b>{t('about.ourVision.kisVCreative')}</b>
                                {t('about.ourVision.preKis')}
                                <b>{t('about.ourVision.foundByBoss')}</b>
                                {t('about.ourVision.withWhoAndWhen')}
                                <br /><br />

                                <b>{t('about.ourVision.mrBoss')}</b>
                                {t('about.ourVision.mrBossDoWhat')}
                                <br /><br />

                                {t('about.ourVision.currently')}
                                <br /><br />

                                {t('about.ourVision.offices')}
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='rounded-2xl bg-white h-[100%] p-[10%]'>
                            <div className='font-extrabold text-2xl' style={{ fontFamily: 'Playfair Display' }}>{t('about.mission')}</div>
                            <div className='mt-[5%] text-[12px] leading-4 sm:text-sm'>
                                {t('about.missionContent.para1')}<br /><br />
                                {t('about.missionContent.para2')} <br /><br />
                                {t('about.missionContent.para3')}
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    )
}