import { useState } from "react"
import { Projects } from "./project_show/Project"
import React from 'react'

const styles = {
    button: {
        margin: 10,
        padding: 10,
        color: '#094276',
        borderRadius: '1rem'
    }
}

export function ServiceManagement() {
    const [openProject, setOpenProject] = useState(false)

    return (
        <>
            <div className="p-10 w-[80vw]">
                <div style={{ width: '100%' }}>
                    <button
                        style={{
                            ...styles.button,
                            backgroundColor: (openProject ? 'rgb(243 244 246)' : 'rgb(209 213 219)')
                        }}
                        onClick={() => {
                            setOpenProject(true)
                        }}
                    >
                        Projects Management
                    </button>
                </div>
                {openProject && <Projects />}
            </div>

        </>
    )
}