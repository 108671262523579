import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import React from 'react'
import './home.css';

// import required modules
import { Navigation } from 'swiper/modules';

import GetInTouchImg from '../../assets/home/getintouch.png'
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

const GetInTouch = forwardRef((props, ref) => {
    const mapSrcHanoi = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.062035054599!2d105.78047451528795!3d21.030203685997638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjHCsDAxJzQ4LjciTiAxMDXCsDQ2JzU3LjYiRQ!5e0!3m2!1svi!2s!4v1565945241740!5m2!1svi!2s";
    const mapSrcHcm = "https://maps.google.com/maps?q=97%20Nguyen%20Cong%20Tru,%20Nguyen%20Thai%20Binh%20Ward,%20District%201,%20Ho%20Chi%20Minh,%20Viet%20Nam.&t=&z=13&ie=UTF8&iwloc=&output=embed"
    const { t } = useTranslation();
    return (
        <div className="p-[10%] getintouch" ref={ref}
            style={{ backgroundImage: `url(${GetInTouchImg})` }}>
            <div className="sm:relative">
                <div className="sm:absolute top-[3%] bg-white rounded-3xl sm:w-[37%]"
                    style={{ boxShadow: '13px 13px 30px rgba(0, 0, 0, 0.25)' }}>
                    <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        className="mySwiper">
                        <SwiperSlide>
                            <iframe
                                className="map-iframe w-[100%] h-[50vh] md:h-[20vw] rounded-t-3xl"
                                title='hanoi'
                                id="link-map"
                                src={mapSrcHanoi}
                                allowFullScreen>
                            </iframe>
                            <div>
                                <div className="bg-white rounded-b-3xl" style={{ margin: "2.5vw 2vw" }}>
                                    <div
                                        className="font-extrabold text-lg"
                                        style={{ fontFamily: '"Playfair Display"' }}
                                    >
                                        {t('home.ourOffices')}
                                    </div>
                                    <div className="font-semibold text-sm" style={{ margin: "1vw 0px" }}>
                                        {t('home.hnHead')}
                                    </div>
                                    <div className="flex items-center ">
                                        <svg
                                            width={16}
                                            height={20}
                                            viewBox="0 0 16 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M8.9875 19.5506C11.125 17.0363 16 10.9424 16 7.51946C16 3.36809 12.4167 0 8 0C3.58333 0 0 3.36809 0 7.51946C0 10.9424 4.875 17.0363 7.0125 19.5506C7.525 20.1498 8.475 20.1498 8.9875 19.5506ZM8 5.01297C8.70724 5.01297 9.38552 5.27705 9.88562 5.74711C10.3857 6.21716 10.6667 6.8547 10.6667 7.51946C10.6667 8.18422 10.3857 8.82176 9.88562 9.29181C9.38552 9.76187 8.70724 10.0259 8 10.0259C7.29276 10.0259 6.61448 9.76187 6.11438 9.29181C5.61428 8.82176 5.33333 8.18422 5.33333 7.51946C5.33333 6.8547 5.61428 6.21716 6.11438 5.74711C6.61448 5.27705 7.29276 5.01297 8 5.01297Z"
                                                fill="#094276"
                                            />
                                        </svg>
                                        &nbsp;&nbsp;&nbsp;
                                        <div className="text-xs">
                                            5th Floor, Technosoft Building Alley 15, Duy Tan St, Dich Vong Hau Ward,
                                            Cau Giay Dist, Hanoi, Vietnam.
                                        </div>
                                    </div>
                                    <div className="flex items-center">
                                        <svg
                                            width={16}
                                            height={16}
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M5.15312 0.769455C4.9125 0.188205 4.27812 -0.12117 3.67188 0.0444546L0.921875 0.794455C0.378125 0.944455 0 1.4382 0 2.0007C0 9.73195 6.26875 16.0007 14 16.0007C14.5625 16.0007 15.0563 15.6226 15.2063 15.0788L15.9563 12.3288C16.1219 11.7226 15.8125 11.0882 15.2312 10.8476L12.2312 9.59758C11.7219 9.38508 11.1313 9.53195 10.7844 9.96008L9.52188 11.5007C7.32188 10.4601 5.54063 8.67883 4.5 6.47883L6.04063 5.21945C6.46875 4.86945 6.61562 4.28195 6.40312 3.77258L5.15312 0.77258V0.769455Z"
                                                fill="#094276"
                                            />
                                        </svg>
                                        &nbsp;&nbsp;&nbsp;<div className="text-xs">(+84) 24 3795 5218</div>
                                    </div>
                                </div>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="mapouter">
                                <div className="gmap_canvas">
                                    <iframe
                                        title='hcm'
                                        src={mapSrcHcm}
                                        frameBorder={0}
                                        scrolling="no"
                                        className="map-iframe w-[100%] h-[50vh] md:h-[20vw] rounded-t-3xl"
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="bg-white rounded-b-3xl" style={{ margin: "2.5vw 2vw" }}>
                                    <div
                                        className="font-extrabold text-lg"
                                        style={{ fontFamily: '"Playfair Display"' }}
                                    >
                                        {t('home.ourOffices')}
                                    </div>
                                    <div className="font-semibold text-sm" style={{ margin: "1vw 0px" }}>
                                        {t('home.hcmHead')}
                                    </div>
                                    <div className="flex items-center">
                                        <svg
                                            width={16}
                                            height={20}
                                            viewBox="0 0 16 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M8.9875 19.5506C11.125 17.0363 16 10.9424 16 7.51946C16 3.36809 12.4167 0 8 0C3.58333 0 0 3.36809 0 7.51946C0 10.9424 4.875 17.0363 7.0125 19.5506C7.525 20.1498 8.475 20.1498 8.9875 19.5506ZM8 5.01297C8.70724 5.01297 9.38552 5.27705 9.88562 5.74711C10.3857 6.21716 10.6667 6.8547 10.6667 7.51946C10.6667 8.18422 10.3857 8.82176 9.88562 9.29181C9.38552 9.76187 8.70724 10.0259 8 10.0259C7.29276 10.0259 6.61448 9.76187 6.11438 9.29181C5.61428 8.82176 5.33333 8.18422 5.33333 7.51946C5.33333 6.8547 5.61428 6.21716 6.11438 5.74711C6.61448 5.27705 7.29276 5.01297 8 5.01297Z"
                                                fill="#094276"
                                            />
                                        </svg>
                                        &nbsp;&nbsp;&nbsp;
                                        <div className="text-xs">
                                            1F, 97 Nguyen Cong Tru, Nguyen Thai Binh Ward, District 1, Ho Chi Minh,
                                            Viet Nam.
                                        </div>
                                    </div>
                                    <div className="flex items-center">
                                        <svg
                                            width={16}
                                            height={16}
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M5.15312 0.769455C4.9125 0.188205 4.27812 -0.12117 3.67188 0.0444546L0.921875 0.794455C0.378125 0.944455 0 1.4382 0 2.0007C0 9.73195 6.26875 16.0007 14 16.0007C14.5625 16.0007 15.0563 15.6226 15.2063 15.0788L15.9563 12.3288C16.1219 11.7226 15.8125 11.0882 15.2312 10.8476L12.2312 9.59758C11.7219 9.38508 11.1313 9.53195 10.7844 9.96008L9.52188 11.5007C7.32188 10.4601 5.54063 8.67883 4.5 6.47883L6.04063 5.21945C6.46875 4.86945 6.61562 4.28195 6.40312 3.77258L5.15312 0.77258V0.769455Z"
                                                fill="#094276"
                                            />
                                        </svg>
                                        &nbsp;&nbsp;&nbsp;
                                        <div className="text-xs">(+84) 24 3795 5218</div>
                                    </div>
                                </div>
                            </div>

                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className='sm:ml-[35%] sm:flex sm:flex-col sm:justify-center bg-white rounded-3xl py-[3vw] px-[8vw] mt-[10%] sm:h-[40vw]'>
                    <div className='font-extrabold text-xl sm:text-2xl my-[5%] sm:mt-0 sm:mb-[1vw]' style={{ fontFamily: 'Playfair Display' }}>{t('home.getInTouch')} </div>
                    <div className='mb-[2vw]'>
                        {t('home.weAreLookingForward')} <br />
                        {t('home.sendUsAMessage')}
                    </div>
                    <div>
                        {t('home.contactVia')}
                    </div>
                </div>

            </div>


        </div>
    )
})

export default GetInTouch