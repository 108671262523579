import { useState } from "react";
import { checkJapaneseWithParam, showValueBasedOnLang } from "../../../../utils";
import ReactQuill from "react-quill";
import customAxios from "../../../../components/api/customaxios";

const styles = {
    reactQuill: {
        fontFamily: 'Lexend Deca'
    }
};

export function EditProject(props) {
    const lang = props.lang
    const setOpen = props.setOpen
    const project = props.project
    const setProject = props.setProject
    const setReload = props.setReload
    const projectsList = props.projectsList
    const setProjectsList = props.setProjectsList
    const projectIndex = props.projectIndex
    const [err, setErr] = useState({
        message: '',
        status: 200
    })

    const handleProjectChange = (propertyName, value) => {
        const projectsListChange = [...projectsList]
        const realPropertyName = checkJapaneseWithParam(lang) ? propertyName + 'Jp': propertyName;
        const nProject = { ...project, [realPropertyName]: value }
        setProject(nProject)
        projectsListChange[projectIndex] = nProject
        setProjectsList(projectsListChange)
    }

    const handleTitleChange = e => {
        handleProjectChange('title', e.target.value)
    }

    const handleContentChange = newContent => {
        handleProjectChange('content', newContent)
    }

    const onSave = () => {
        customAxios.put(`/projects/${project.id}`, project)
            .then(res => setErr(res.data))
            .catch(e => console.log(e))
        setReload(true)
    }

    const onDelete = () => {
        customAxios.delete(`/projects/${project.id}`)
            .then(res => setErr(res.data))
            .catch(e => console.log(e))
        setReload(true)
        setOpen(false)
    }

    const onCancel = () => {
        setReload(true)
        setOpen(false)
    }

    return (
        <>
            <div style={{ textTransform: 'uppercase', fontWeight: 800 }}>Edit project {parseInt(projectIndex) + 1} {lang}</div>
            <div style={{ margin: '5px 0' }}>
                <span>Title:</span> <br />
                <input
                    type="text"
                    style={{ width: '100%' }}
                    value={showValueBasedOnLang(lang, project, 'title')}
                    onChange={handleTitleChange}
                />
            </div>
            <div>Content:</div>
            <ReactQuill theme="snow"
                // modules={modules}
                value={showValueBasedOnLang(lang, project, 'content')}
                onChange={handleContentChange}
                style={styles.reactQuill}
            />
            <div>Created At: {project.createdAt}</div>
            <div>Updated At: {project.updatedAt}</div>
            <button
                style={{
                    backgroundColor: '#094276',
                    color: 'white',
                    margin: 10
                }}
                onClick={onSave}>
                Save
            </button>
            <button
                style={{
                    backgroundColor: 'red',
                    color: 'white',
                    margin: 10
                }}
                onClick={onDelete}>
                {'Delete'}
            </button>
            <button
                style={{
                    backgroundColor: '#a16207',
                    color: 'white',
                    margin: 10
                }}
                onClick={onCancel}>
                {'Cancel'}
            </button>
            <div className={err.status !== 200 ? 'text-red-500' : 'text-green-500'}>{err.message}</div>
        </>
    )
}