import KisLogo from '../assets/kis-logo.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectOpenNews } from '../redux/newsSlice'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function stringStartsWithInList(inputString, stringList) {
    for (const str of stringList) {
        if (inputString.startsWith(str)) {
            return true;
        }
    }
    if (inputString === "/")
        return true
    return false;
}

const stringList = ["/about", "/services", "/careers", '/news'];

export function Header({ onButtonClick, handleChange }) {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const openNews = useSelector(selectOpenNews);

    console.log(openNews);


    const handleButtonClick = async () => {
        await navigate('/')
        onButtonClick()
    };

    return (
        <div className="sticky top-0 z-50 w-[100%]" hidden={!stringStartsWithInList(location.pathname, stringList)}>
            <div className="py-[0.3%] bg-[#0B1D2C] w-[100%]">
                <div
                    className="pr-[4vw] w-[100%] font-bold text-white flex flex-row items-center justify-end space-x-2">
                    <svg width="36" height="18" viewBox="0 0 36 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="&#240;&#159;&#166;&#134; icon &#34;Language&#34;">
                            <path id="Vector" d="M8.55562 7.88625C8.35875 7.20562 8.11687 6.01875 8.11687 6.01875H8.08875C8.08875 6.01875 7.84687 7.20562 7.65 7.88625L7.02562 9.99563H9.16875L8.55562 7.88625ZM34.65 0H18.9V18H34.65C35.3981 18 36 17.3981 36 16.65V1.35C36 0.601875 35.3981 0 34.65 0ZM33.3 6.75C33.3 7.12125 32.9963 7.425 32.625 7.425H31.9837C31.5956 8.7525 30.7631 10.0912 29.5819 11.3569C30.0544 11.7169 30.5437 12.06 31.05 12.3694C31.3594 12.5606 31.4606 12.96 31.2806 13.2806L30.8363 14.0625C30.645 14.3944 30.2231 14.5012 29.8969 14.3044C29.1881 13.8656 28.5187 13.3987 27.9056 12.9037C27.2925 13.3931 26.6287 13.8656 25.9144 14.3044C25.5881 14.5012 25.1663 14.3944 24.975 14.0625L24.5306 13.2806C24.3506 12.9656 24.4519 12.5606 24.7669 12.3694C25.29 12.0487 25.7794 11.7112 26.235 11.3569C25.7906 10.8844 25.3969 10.4006 25.0537 9.91125C24.8287 9.59063 24.93 9.14625 25.2619 8.94938L25.6275 8.73L26.0381 8.48812C26.3419 8.30812 26.7356 8.3925 26.9381 8.67937C27.2194 9.07312 27.5456 9.46688 27.9169 9.855C28.6762 9.05625 29.2556 8.22937 29.6044 7.425H23.175C22.8037 7.425 22.5 7.12125 22.5 6.75V5.85C22.5 5.47875 22.8037 5.175 23.175 5.175H26.775V4.275C26.775 3.90375 27.0787 3.6 27.45 3.6H28.35C28.7212 3.6 29.025 3.90375 29.025 4.275V5.175H32.625C32.9963 5.175 33.3 5.47875 33.3 5.85V6.75ZM0 1.35V16.65C0 17.3981 0.601875 18 1.35 18H17.1V0H1.35C0.601875 0 0 0.601875 0 1.35ZM3.31312 13.5056L6.5475 3.99375C6.64313 3.71812 6.89625 3.53812 7.18875 3.53812H9.01688C9.30375 3.53812 9.5625 3.72375 9.65812 3.99375L12.8925 13.5056C13.0387 13.9444 12.7181 14.4 12.2513 14.4H10.9631C10.8176 14.3999 10.6759 14.3527 10.5593 14.2656C10.4428 14.1784 10.3575 14.0559 10.3162 13.9162L9.7875 12.1219H6.40125L5.88937 13.9106C5.805 14.1975 5.54062 14.4 5.2425 14.4H3.95438C3.49313 14.4 3.16688 13.9444 3.31312 13.5056Z" fill="white" />
                        </g>
                    </svg>
                    <select className='bg-[#0B1D2C]'
                        onChange={handleChange}
                    >
                        <option value={"en"} selected={localStorage.getItem('i18nextLng') === 'en'}>English</option>
                        <option value={"jp"} selected={localStorage.getItem('i18nextLng') === 'jp'}>日本語</option>
                    </select>
                </div>
            </div>
            <Disclosure as="nav" className="bg-[#243746]">
                {({ open }) => (
                    <>
                        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                            <div className="relative flex h-16 items-center justify-between">
                                <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                                    {/* Mobile menu button*/}
                                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                        <span className="absolute -inset-0.5" />
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>
                                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                                    <a className="flex flex-shrink-0 items-center"
                                        href={"/"}
                                    >
                                        <img
                                            className="h-10 w-auto"
                                            src={KisLogo}
                                            style={{ filter: "brightness(170%)" }} // Update the brightness value to make the image lighter
                                            alt="Your Company"
                                        />
                                    </a>
                                    <div className="hidden sm:ml-6 sm:block">
                                        <div className="flex space-x-4">
                                            <a
                                                href={"/"}
                                                className={classNames(
                                                    location.pathname === '/' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                    'rounded-md px-3 py-2 text-sm font-medium'
                                                )}
                                                aria-current={location.pathname === '/' ? 'page' : undefined}
                                            >
                                                {t('header.home')}
                                            </a>
                                            <a
                                                href={"/about"}
                                                className={classNames(
                                                    location.pathname === '/about' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                    'rounded-md px-3 py-2 text-sm font-medium'
                                                )}
                                                aria-current={location.pathname === '/about' ? 'page' : undefined}
                                            >
                                                {t('header.about')}
                                            </a>
                                            <Menu as="div" className="relative ml-3">
                                                <div>
                                                    <Menu.Button className="relative flex bg-[#243746] text-sm hover:bg-gray-700 hover:rounded-md">
                                                        <span className="absolute -inset-1.5" />
                                                        <span className="sr-only">Open user menu</span>
                                                        <div className={classNames(
                                                            location.pathname.startsWith('/services') ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                            'rounded-md px-3 py-2 text-sm font-medium'
                                                        )}>
                                                            <div className='flex flex-row items-center'>
                                                                {t('header.services')} &nbsp;
                                                                <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M7.08904 9.4172L0.247472 2.5756C-0.0824908 2.24563 -0.0824908 1.71068 0.247472 1.38075L1.04543 0.582791C1.37483 0.253392 1.90869 0.252758 2.23887 0.581383L7.68648 6.00347L13.1341 0.581383C13.4642 0.252758 13.9981 0.253392 14.3275 0.582791L15.1255 1.38075C15.4554 1.71071 15.4554 2.24567 15.1255 2.5756L8.28392 9.4172C7.95396 9.74713 7.419 9.74713 7.08904 9.4172Z" fill="white" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    href={"/services/outsource"}
                                                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                                >
                                                                    {t('header.outsrcService')}
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    href={"/services/consult"}
                                                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                                >
                                                                    {t('header.consult')}
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    href={"/services/humanResrc"}
                                                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                                >
                                                                    {t('header.humanResrc')}
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    href={"/services/product"}
                                                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                                >
                                                                    {t('header.product')}
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                            <a

                                                href={"/careers"}
                                                className={classNames(
                                                    location.pathname === '/careers' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                    'rounded-md px-3 py-2 text-sm font-medium'
                                                )}
                                                aria-current={location.pathname === '/careers' ? 'page' : undefined}
                                            >
                                                {t('header.careers')}
                                            </a>
                                            {openNews && (
                                                <a

                                                    href={"/news"}
                                                    className={classNames(
                                                        location.pathname === '/news' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                        'rounded-md px-3 py-2 text-sm font-medium'
                                                    )}
                                                    aria-current={location.pathname === '/about' ? 'page' : undefined}
                                                >
                                                    {t('header.news')}
                                                </a>
                                            )}



                                        </div>
                                    </div>
                                </div>
                                <div className="absolute inset-y-0 right-0 flex sm:w-[20%] sm:w-fit items-center sm:static sm:inset-auto sm:ml-6 sm:pr-0 mr-[1%]">
                                    { }
                                    <button onClick={handleButtonClick}
                                        type="button"
                                        className="relative rounded-lg sm:rounded-full bg-[#118686] py-[0.5vw] px-[1vw] text-white 
                                        text-[10px] sm:text-[1.2vw] 
                                        font-bold uppercase shadow-xl"
                                    >
                                        {t('header.contact')}
                                    </button>

                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="sm:hidden">
                            <div className="space-y-1 px-2 pb-3 pt-2">

                                <Disclosure.Button

                                    as="a"
                                    href='/'
                                    className={classNames(
                                        location.pathname === '/' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        'block rounded-md px-3 py-2 text-base font-medium'
                                    )}
                                    aria-current={location.pathname === '/' ? 'page' : undefined}
                                >
                                    {t('header.home')}
                                </Disclosure.Button>
                                <Disclosure.Button

                                    as="a"
                                    href='/about'
                                    className={classNames(
                                        location.pathname === '/about' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        'block rounded-md px-3 py-2 text-base font-medium'
                                    )}
                                    aria-current={location.pathname === '/' ? 'page' : undefined}
                                >
                                    {t('header.about')}
                                </Disclosure.Button>

                                <Disclosure.Button

                                    as="a"
                                    href='/services/outsource'
                                    className={classNames(
                                        location.pathname.startsWith('/services/outsource') ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        'block rounded-md px-3 py-2 text-base font-medium'
                                    )}
                                    aria-current={location.pathname.startsWith('/services') ? 'page' : undefined}
                                >
                                    {t('header.outsrcService')}
                                </Disclosure.Button>

                                <Disclosure.Button

                                    as="a"
                                    href='/services/consult'
                                    className={classNames(
                                        location.pathname.startsWith('/services/consult') ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        'block rounded-md px-3 py-2 text-base font-medium'
                                    )}
                                    aria-current={location.pathname.startsWith('/services') ? 'page' : undefined}
                                >
                                    {t('header.consult')}
                                </Disclosure.Button>

                                <Disclosure.Button

                                    as="a"
                                    href='/services/humanResrc'
                                    className={classNames(
                                        location.pathname.startsWith('/services/humanResrc') ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        'block rounded-md px-3 py-2 text-base font-medium'
                                    )}
                                    aria-current={location.pathname.startsWith('/services') ? 'page' : undefined}
                                >
                                    {t('header.humanResrc')}
                                </Disclosure.Button>

                                <Disclosure.Button

                                    as="a"
                                    href='/services/product'
                                    className={classNames(
                                        location.pathname === '/services/product' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        'block rounded-md px-3 py-2 text-base font-medium'
                                    )}
                                    aria-current={location.pathname === '/services/product' ? 'page' : undefined}
                                >
                                    {t('header.product')}
                                </Disclosure.Button>

                                <Disclosure.Button

                                    as="a"
                                    href='/careers'
                                    className={classNames(
                                        location.pathname === '/careers' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        'block rounded-md px-3 py-2 text-base font-medium'
                                    )}
                                    aria-current={location.pathname === '/careers' ? 'page' : undefined}
                                >
                                    {t('header.careers')}
                                </Disclosure.Button>

                                {openNews && (
                                    <Disclosure.Button

                                        as="a"
                                        href='/news'
                                        className={classNames(
                                            location.pathname === '/news' ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                            'block rounded-md px-3 py-2 text-base font-medium'
                                        )}
                                        aria-current={location.pathname === '/news' ? 'page' : undefined}
                                    >
                                        {t('header.news')}
                                    </Disclosure.Button>
                                )}
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>

    )
}