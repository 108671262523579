import "./App.css";
import { Header } from "./components/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { About } from "./pages/About";
import { Footer } from "./components/Footer";
import { OutSourcing } from "./pages/Outsourcing";
import { Consult } from "./pages/Consult";
import { HumanResrc } from "./pages/HumanResrc";
import { Career } from "./pages/Career";
import { useRef } from "react";
import { Suspense } from "react";
import "./i18n";
import i18n from "i18next";
import Login from "./pages/Login";
import { News } from "./pages/News";
import Admin from "./pages/Admin";
import React from "react";
import { Product } from "./pages/Product";

function App() {
  const targetDivRef = useRef(null);
  const onChange = (event) => {
    i18n.changeLanguage(event.target.value);
    localStorage.setItem('i18nextLng', event.target.value);
  };

  const scrollToDiv = () => {
    // Check if the current path is '/' before scrolling
    if (window.location.pathname === "/" && targetDivRef.current) {
      targetDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Suspense fallback={"Loading..."}>
      <div className="text-[12px] leading-3 sm:text-base">
        <Router>
          <>
            <Header
              onButtonClick={scrollToDiv}
              // locale={locale}
              handleChange={onChange}
            />
            <Routes>
              <Route path="/" element={<Home targetDivRef={targetDivRef} />} />
              <Route path="/about" element={<About />} />
              <Route path="/services/outsource" element={<OutSourcing />} />
              <Route path="/services/consult" element={<Consult />} />
              <Route path="/services/humanresrc" element={<HumanResrc />} />
              <Route path="/careers" element={<Career />} />
              <Route path="/login" element={<Login />} />
              <Route path="/news" element={<News />} />
              <Route path="/services/product" element={<Product />} />
              <Route path="/admin/*" element={<Admin />} />
            </Routes>
            <Footer />
          </>
        </Router>
      </div>
    </Suspense>
  );
}

export default App;
