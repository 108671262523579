import Outsource from '../../assets/home/outsource.png'
import HumanResec from '../../assets/home/humanresrc.png'
import Consult from '../../assets/home/consult.png'
import React from 'react'

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const services = [
    {
        image: Outsource,
        title: 'header.outsrcService',
        url: '/services/outsource'
    },
    {
        image: HumanResec,
        title: 'header.humanResrc',
        url: '/services/humanresrc'
    },
    {
        image: Consult,
        title: 'header.consult',
        url: '/services/consult'
    },
]

export function Services() {
    const navigate = useNavigate();
    const {t} = useTranslation();   

    return (
        <div id='services-home' className="mx-[10%] my-[3vw]">
            <div className="sm:flex flex-wrap row py-[3vw]">
                <div className='flex-1'>
                    <div className='font-light'>{t('services.letUsIntro')}</div>
                    <div className='font-extrabold text-2xl sm:text-3xl'
                        style={{ fontFamily: 'Playfair Display' }}>
                        {t('services.ourServices')}
                    </div>
                </div>
                <div className='flex-1 flex items-end font-light'>
                    <div className=' text-left my-[10%] sm:my-0 sm:text-right'>
                        {t('home.digitalTransformation')}
                    </div>
                </div>
            </div>

            <div className="flex flex-wrap sm:justify-between">
                {services.map((item, index) => {

                    return (
                        <div key={index} className='group mt-[10vw] sm:mt-0 sm:w-[20%] sm:mx-[6%] sm:h-fit pb-[6vh] rounded-lg hover:bg-[#094276] hover:text-white transition-all duration-300'
                            style={{ boxShadow: '0px 10px 18px rgba(122.19, 122.19, 122.19, 0.25)' }}>
                            <img src={item.image} className='rounded-lg w-[100%]' alt="" />
                            <div className='text-center w-[70%] mx-[15%] mt-[2vw] text-base sm:text-[1.2vw] font-bold h-[60px] '>
                                {t(item.title)}
                            </div>
                            <div className='grid justify-items-center sm:mt-[2vw] sm:mb-[1vw] mt-[1vh]'>
                                <button onClick={() => navigate(item.url)}
                                    className='rounded-full border-blue-600 group-hover:border-white 
                                    border-2 w-10 h-10 sm:w-[3.4vw] sm:h-[3.4vw] group-hover:w-auto 
                                    group-hover:flex group-hover:px-3 grid place-items-center'>
                                    <span className='hidden group-hover:inline sm:text-[1.1vw]'>{t('home.readmore')}&nbsp;&nbsp;</span>
                                    <svg
                                        viewBox="0 0 14 24"
                                        className='fill-current text-[#094276] group-hover:text-[white] 
                                        sm:w-[0.7vw] sm:h-[1.2vw]
                                        w-[10.5px] h-[18px]'
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.6287 12.9327L3.36185 23.6136C2.86669 24.1288 2.06392 24.1288 1.56881 23.6136L0.371356 22.3679C-0.122957 21.8536 -0.123907 21.0202 0.369243 20.5047L8.50588 12L0.369243 3.49529C-0.123907 2.97983 -0.122957 2.14637 0.371356 1.63211L1.56881 0.38635C2.06397 -0.128783 2.86674 -0.128783 3.36185 0.38635L13.6286 11.0673C14.1238 11.5824 14.1238 12.4176 13.6287 12.9327Z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    )
                }
                )}
            </div>
        </div>
    )
}