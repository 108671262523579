import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom"

function stringStartsWithInList(inputString, stringList) {
    for (const str of stringList) {
        if (inputString.startsWith(str)) {
            return true;
        }
        if (inputString === "/")
            return true
    }
    return false;
}

const stringList = ["/about", "/services", "/careers", '/news'];

export function Footer() {
    const location = useLocation();
    const { t } = useTranslation()

    return (
        <div className="relative" hidden={!stringStartsWithInList(location.pathname, stringList)}>
            <div className="flex flex-wrap bg-[#243746] text-xs text-white px-[10%] gap-10 py-[3vw]">
                <div className="sm:max-w-[30vw]">
                    <div className="font-bold">
                        {t("footer.companyFullName")}
                    </div>
                    <div className="sm:text-sm my-[1.5vw]">
                        “{t('footer.footerContext')}”
                    </div>
                    <div className="flex items-center mt-[2.5vw]">
                        <svg className="w-7 h-7" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.3795 18.525C12.2832 16.1426 16.625 10.3684 16.625 7.125C16.625 3.19141 13.4336 0 9.5 0C5.56641 0 2.375 3.19141 2.375 7.125C2.375 10.3684 6.7168 16.1426 8.62051 18.525C9.07695 19.0928 9.92305 19.0928 10.3795 18.525ZM9.5 4.75C10.1299 4.75 10.734 5.00022 11.1794 5.44562C11.6248 5.89102 11.875 6.49511 11.875 7.125C11.875 7.75489 11.6248 8.35898 11.1794 8.80438C10.734 9.24978 10.1299 9.5 9.5 9.5C8.87011 9.5 8.26602 9.24978 7.82062 8.80438C7.37522 8.35898 7.125 7.75489 7.125 7.125C7.125 6.49511 7.37522 5.89102 7.82062 5.44562C8.26602 5.00022 8.87011 4.75 9.5 4.75Z" fill="white" />
                        </svg>
                        &nbsp;&nbsp;
                        <div className="sm:text-xs">5th Floor, Technosoft Building Alley 15, Duy Tan St, Dich Vong Hau Ward, Cau Giay Dist, Hanoi, Vietnam.</div>
                    </div>
                    <div className="flex items-center mt-[0.5vw]">
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_128_442)">
                                <path d="M6.11934 0.912995C5.83359 0.222761 5.08027 -0.144622 4.36035 0.0520574L1.09473 0.942682C0.449023 1.12081 0 1.70714 0 2.3751C0 11.556 7.44414 19.0001 16.625 19.0001C17.293 19.0001 17.8793 18.5511 18.0574 17.9054L18.948 14.6398C19.1447 13.9198 18.7773 13.1665 18.0871 12.8808L14.5246 11.3964C13.9197 11.144 13.2184 11.3185 12.8064 11.8269L11.3072 13.6564C8.69473 12.4206 6.57949 10.3054 5.34375 7.69288L7.17324 6.19737C7.68164 5.78174 7.85605 5.08409 7.60371 4.47921L6.11934 0.916706V0.912995Z" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_128_442">
                                    <rect width="19" height="19" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>

                        &nbsp;&nbsp;
                        <div className="sm:text-xs">
                            (+84) 24 3795 5218
                        </div>
                    </div>
                    <div className="flex items-center mt-[0.5vw]">
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.78125 2.375C0.797852 2.375 0 3.17285 0 4.15625C0 4.7166 0.263477 5.24355 0.7125 5.58125L8.7875 11.6375C9.21055 11.9529 9.78945 11.9529 10.2125 11.6375L18.2875 5.58125C18.7365 5.24355 19 4.7166 19 4.15625C19 3.17285 18.2021 2.375 17.2188 2.375H1.78125ZM0 6.53125V14.25C0 15.56 1.06504 16.625 2.375 16.625H16.625C17.935 16.625 19 15.56 19 14.25V6.53125L10.925 12.5875C10.0789 13.2221 8.92109 13.2221 8.075 12.5875L0 6.53125Z" fill="white" />
                        </svg>
                        &nbsp;&nbsp;
                        <div className="sm:text-xs">
                            info@kis-v.com
                        </div>
                    </div>
                </div>
                <div className="sm:max-w-[30vw]">
                    <div className="font-bold">
                        {t('footer.kisGroup')}
                    </div>
                    <div className="sm:text-sm mt-[1.5vw]">
                        {t('footer.kisHcmOffice')}
                    </div>
                    <div className="flex items-center sm:text-xs font-light">
                        1F, 97 Nguyen Cong Tru, Nguyen Thai Binh Ward, District 1, Ho Chi Minh, Viet Nam.
                    </div>
                    <div className="sm:text-sm mt-[1.5vw]">
                        {t('footer.kisJp')}
                    </div>
                    <div className="flex items-center sm:text-xs font-light">
                        {t('footer.placeLaidon')}
                    </div>
                    <div className="sm:text-sm mt-[1.5vw]">
                        {t('footer.kisUS')}
                    </div>
                    <div className="flex items-center sm:text-xs font-light">
                        38 N. Almaden Blvd., Unit 125, San Jose, CA 95110.
                    </div>
                </div>
                <div className="">
                    <div className="font-bold">
                        {t('footer.directory')}
                    </div>
                    <ul className="list-disc sm:text-sm mt-[1.5vw] pl-[1rem]">
                        <li><a href="/about">{t('header.about')}</a></li>
                        <li><a href="/services/outsource">{t('header.services')}</a></li>
                        <li><a href="/about">{t('home.offices')}</a></li>
                    </ul>
                    <div className="font-bold mt-[2vw]">
                        Follow us
                    </div>
                    <div className="flex">
                        <a href="https://www.facebook.com/kiscreativevn">
                            <svg width="25" height="25" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27 2H5C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5L2 27C2 27.7956 2.31607 28.5587 2.87868 29.1213C3.44129 29.6839 4.20435 30 5 30H13.5781V20.4806H9.64062V16H13.5781V12.585C13.5781 8.70062 15.8906 6.555 19.4325 6.555C21.1287 6.555 22.9025 6.8575 22.9025 6.8575V10.67H20.9481C19.0225 10.67 18.4219 11.865 18.4219 13.0906V16H22.7206L22.0331 20.4806H18.4219V30H27C27.7956 30 28.5587 29.6839 29.1213 29.1213C29.6839 28.5587 30 27.7956 30 27V5C30 4.20435 29.6839 3.44129 29.1213 2.87868C28.5587 2.31607 27.7956 2 27 2Z" fill="white" />
                            </svg>
                        </a>
                        &nbsp;
                        <a href="https://www.linkedin.com/company/kis-vietnam-creative/">
                            <svg width="25" height="25" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M28 2H3.99375C2.89375 2 2 2.90625 2 4.01875V27.9813C2 29.0938 2.89375 30 3.99375 30H28C29.1 30 30 29.0938 30 27.9813V4.01875C30 2.90625 29.1 2 28 2ZM10.4625 26H6.3125V12.6375H10.4688V26H10.4625ZM8.3875 10.8125C7.05625 10.8125 5.98125 9.73125 5.98125 8.40625C5.98125 7.08125 7.05625 6 8.3875 6C9.7125 6 10.7937 7.08125 10.7937 8.40625C10.7937 9.7375 9.71875 10.8125 8.3875 10.8125ZM26.0187 26H21.8687V19.5C21.8687 17.95 21.8375 15.9563 19.7125 15.9563C17.55 15.9563 17.2188 17.6438 17.2188 19.3875V26H13.0688V12.6375H17.05V14.4625H17.1062C17.6625 13.4125 19.0188 12.3062 21.0375 12.3062C25.2375 12.3062 26.0187 15.075 26.0187 18.675V26Z" fill="white" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            <div className="bg-[#0B1D2C] text-[12px] sm:text-base text-[#76A6D2] px-[10%] sm:text-sm py-[0.3vw]">
                <span style={{ fontWeight: 300 }}>Copyright 2023</span> <span style={{ fontWeight: 700 }}>KIS Vietnam Creative Co., Ltd.</span>
            </div>
            {/* <div className="absolute bottom-[1vw] right-[2vw]">
                <svg width="5vw" height="5vw" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_134_85)">
                        <circle cx="49" cy="49" r="47" fill="#D9D9D9" />
                    </g>
                    <path d="M30.3125 32C27.9316 32 26 33.9036 26 36.25C26 37.587 26.6379 38.8443 27.725 39.65L47.275 54.1C48.2992 54.8526 49.7008 54.8526 50.725 54.1L70.275 39.65C71.3621 38.8443 72 37.587 72 36.25C72 33.9036 70.0684 32 67.6875 32H30.3125ZM26 41.9167V60.3333C26 63.4589 28.5785 66 31.75 66H66.25C69.4215 66 72 63.4589 72 60.3333V41.9167L52.45 56.3667C50.4016 57.8807 47.5984 57.8807 45.55 56.3667L26 41.9167Z" fill="#094276" />
                    <defs>
                        <filter id="filter0_d_134_85" x="0" y="0" width="108" height="108" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dx="5" dy="5" />
                            <feGaussianBlur stdDeviation="3.5" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_134_85" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_134_85" result="shape" />
                        </filter>
                    </defs>
                </svg>

            </div> */}
        </div>
    )
}