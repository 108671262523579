import React, { useState } from 'react';
import customAxios from '../api/customaxios';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';


/*
 * Example Parchment format from
 * https://quilljs.com/guides/cloning-medium-with-parchment/
 * See the video example in the guide for a complex format
 */
let Inline = Quill.import('blots/inline');
class BoldBlot extends Inline { }
BoldBlot.blotName = 'bold';
BoldBlot.tagName = 'strong';
Quill.register('formats/bold', BoldBlot);

/*
 * Editor component with default and custom formats
 */

export function CreateNews(props) {

    const [title, setTitle] = useState(props?.title);
    const [content, setContent] = useState(props?.content)
    const updateEn = props?.updateEn
    const newsId = props?.id
    const [err, setErr] = useState({
        message: '',
        status: 200
    })

    function createNews() {
        const form = { title: title, content: content }

        customAxios.post('/kis-news', form)
            .then((res) => {
                setErr({ message: res.data.message, status: res.data.status })
            })
            .catch((err) => {
                setErr({ message: 'No connection!', status: err.status })
                console.log(err);
            })
    }

    function updateNews() {
        if (updateEn) {
            const form = { title: title, content: content }

            customAxios.put(`/kis-news/${newsId}`, form)
                .then((res) => {
                    setErr({ message: res.data.message, status: res.data.status })
                })
                .catch((err) => {
                    setErr({ message: 'No connection!', status: err.status })
                    console.log(err);
                })
        } else {
            const form = { titleJp: title, contentJp: content }

            customAxios.put(`/kis-news/${newsId}`, form)
                .then((res) => {
                    setErr({ message: res.data.message, status: res.data.status })
                })
                .catch((err) => {
                    setErr({ message: 'No connection!', status: err.status })
                    console.log(err);
                })
        }
    }

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'color': [] }, { 'background': [] }],
            ['link', 'image'],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']
        ],
    };

    return (
        <>
            <div className='flex flex-col h-[80vh] pb-1'>
                <div className='w-[100%] text-center text-white bg-[#094276] text-2xl rounded-t-2xl px-2 py-3'>

                    {(updateEn === null ? 'Create' : updateEn ? 'Update English' : 'Update Japanese') + ' News'}
                </div>
                <div className='px-[5%] py-[3%] overflow-auto grow w-[100%]'>
                    <div>Title:</div>
                    <textarea
                        type="text"
                        className='border rounded-2xl w-[100%] p-2 h-auto'
                        value={title}
                        onChange={event => setTitle(event.target.value)}
                    />

                    <div>Content</div><br />
                    <ReactQuill theme="snow"
                        modules={modules}
                        value={content}
                        onChange={setContent}
                        style={{ fontFamily: 'Lexend Deca' }}
                    >
                        {/* <div style={{ fontFamily: 'Lexend Deca' }} /> */}
                    </ReactQuill>
                    <br />
                    {/* <ChildModal /> */}
                    <div className={err.status !== 200 ? 'text-red-500' : 'text-green-500'}>{err.message}</div>

                    <button className='rounded-2xl py-1 px-3 my-4 bg-[#094276] text-white'
                        onClick={updateEn === null ? createNews : updateNews}>
                        {updateEn === null ? 'Create' : 'Update'} News
                    </button>
                </div>
                {/* <button className='border border-1 w-fit rounded-2xl py-1 px-3 my-4 bg-[#094276] text-white'
                onClick={btnclick}>
                Confirm News
            </button>
            <br /> */}
            </div>
        </>
    );
}