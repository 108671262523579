import OutSrc from '../../assets/services/outsrc.png'

import Image9 from '../../assets/services/image 13 (1).png'
import Image2 from '../../assets/services/image 13.png'
import Image3 from '../../assets/services/image 14.png'
import Image4 from '../../assets/services/image 15.png'
import Image5 from '../../assets/services/image 16.png'
import Image6 from '../../assets/services/image 17.png'
import Image7 from '../../assets/services/image 18.png'
import Image8 from '../../assets/services/image 19.png'
import { useTranslation } from 'react-i18next'

const outsrcServices = [
    {
        img: Image2,
        name: "Mobile Application Developement"
    },
    {
        img: Image3,
        name: "Web Application Developement"
    },
    {
        img: Image4,
        name: "Information System & Decision Support System"
    },
    {
        img: Image5,
        name: "System Dynamos & Infrastructure construction"
    },
    {
        img: Image6,
        name: "Machine learning & AI model development"
    },
    {
        img: Image7,
        name: "Enterprise Solutions (ERP, CRM, ...)"
    },
    {
        img: Image8,
        name: "Digital Transformation (DX)"
    },
    {
        img: Image9,
        name: "Embedded System & Robotic"
    },
]

export function IntroSerice() {
    const { t } = useTranslation()
    return (
        <div className="m-[10%]">
            <div className="flex flex-wrap items-end mb-[10%]">
                <div className="">
                    <div className="font-light">{t('services.letUsIntro')}</div>
                    <div className="font-extrabold text-2xl sm:text-4xl" style={{ fontFamily: 'Playfair Display' }}>
                        {t('services.outsrcService')}
                    </div>
                </div>
                <div className="grow"></div>
                <div className="text-left sm:text-right font-light my-[10vw] sm:my-0 sm:max-w-[40vw]">
                    {t('services.introContent')}
                </div>
            </div>
            <div className="rounded-2xl">
                <img src={OutSrc} alt="OutSrc" className='rounded-t-2xl' />
                <div className='py-[5%] px-[10%] bg-[#094276] text-white rounded-b-2xl'>
                    <div className='font-bold text-xl sm:text-2xl mb-[5%]'>{t('services.outsrcService')}</div>
                    <div className='text-[12px] leading-3 sm:text-base'>
                    {t('services.outsrcContent.para1')}<br /> <br />
                    {t('services.outsrcContent.para2')}
                    </div>
                </div>
            </div>
            <div className='mt-[5%]'>
                <div className='text-center font-bold mb-[5%]' style={{ fontFamily: 'Playfair Display' }}>
                    {t('services.intro8Services')}
                </div>
                <div className='flex flex-wrap'>
                    <div className='grid grid-cols-2 sm:grid-cols-4 gap-4 sm:gap-10'>
                        {outsrcServices.map((item, index) => (
                            <div key={index}
                                className='rounded-2xl my-[1%] sm:my-0'
                                style={{
                                    boxShadow: '13px 13px 30px rgba(0, 0, 0, 0.25)'
                                }}>
                                <img src={item.img} alt={item.name} className='rounded-t-2xl w-[100%]' />
                                <div className='text-center px-[5%] py-[10%] font-bold text-xs sm:text-[1.2vw]'>
                                    {item.name}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}