import { ServiceHero } from "../components/outsource/Hero";
import ConsultImg from '../assets/services/consult.png'
import { useTranslation } from "react-i18next";

export function Consult() {
    const { t } = useTranslation()
    return (
        <div>
            <ServiceHero />
            <div className="m-[10%]">
                <div className="flex flex-wrap items-end mb-[10%]">
                    <div className="">
                        <div className="font-light">{t('services.letUsIntro')}</div>
                        <div className="font-extrabold text-2xl sm:text-4xl uppercase" style={{ fontFamily: 'Playfair Display' }}>{t('header.consult')}</div>
                    </div>
                    <div className="grow"></div>
                    <div className="text-left sm:text-right font-light my-[10%] sm:my-0 sm:max-w-[40vw]">
                        {t('services.introContent')}
                    </div>
                </div>
                <div className="rounded-2xl">
                    <img src={ConsultImg} alt="OutSrc" className='rounded-t-2xl' />
                    <div className='py-[5%] px-[10%] bg-[#094276] text-white rounded-b-2xl'>
                        <div className='font-bold text-xl sm:text-2xl mb-[5%]'>{t('header.consult')}</div>
                        <div className="text-xs sm:text-base">
                            <div dangerouslySetInnerHTML={{__html: t('services.consultContent')}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}