import React, { useEffect, useState } from "react";
import customAxios from "../../components/api/customaxios";
import { Box, Modal } from "@mui/material";
import { CreateNews } from "../../components/news/CreateNews";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';
import { useDispatch, useSelector } from "react-redux";
import { selectOpenNews, setOpenNews } from "../../redux/newsSlice";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    height: '80vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    borderRadius: '1rem'
};

const styles = {
    td: {
        textAlign: 'center'
    }
}

export function NewsManagement() {

    const dispatch = useDispatch()

    const [newsList, setNewsList] = useState([])
    const [open, setOpen] = React.useState(false);
    const [updateEn, setUpdatedEn] = useState(true)
    const [err, setErr] = useState({
        message: '',
        status: 200
    })
    const [news, setNews] = useState({
        id: -1,
        title: '',
        titleJp: '',
        content: '',
        contentJp: '',
        hidden: false
    })
    const openNews = useSelector(selectOpenNews);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        customAxios.get('/kis-news')
            .then(res => {
                setNewsList(res.data)
            })
            .catch(err => {
                console.log(err);
                setErr(err)
            })
    }, [open, news, err])

    function deleteNews(newsid) {
        customAxios.delete(`/kis-news/${newsid}`)
            .then(res => {
                setErr(res.data)
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleOpenNewsChange = (isOpen) => {
        console.log(isOpen);
        dispatch(setOpenNews(isOpen));
    };

    return (
        <div className="mx-20 my-20">
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, overscrollBehavior: 'auto' }}>
                    <CreateNews id={news?.id} updateEn={updateEn} title={updateEn ? news?.title : news?.titleJp} content={updateEn ? news?.content : news?.contentJp} />
                </Box>
            </Modal>
            <div className="text-2xl font-extrabold uppercase">
                News Management &nbsp;
                Off
                <Switch 
                    value={openNews}
                    onChange={() => handleOpenNewsChange(!openNews)}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />On
            </div>
            <button
                onClick={() => {
                    setUpdatedEn(null)
                    setNews(null)
                    handleOpen()
                }}
                className="rounded-2xl py-1 px-3 my-4 bg-[#094276] text-white"
            >Create news
            </button>
            <div className={err.status !== 200 ? 'text-red-500' : 'text-green-500'}>{err.message}</div>

            <table className="table-auto border-2">
                <thead className="text-white bg-[#094276]">
                    <tr className="border-2">
                        <th className="w-[30vw] border-2 p-2">Title</th>
                        <th className="border-2 p-2">Fix English Version</th>
                        <th className="border-2 p-2">Fix Japanese Version</th>
                        <th className="border-2 p-2">Hidden</th>
                        <th className="border-2 p-2">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {newsList.map((item) => (
                        <tr className="border-2" key={item.id}>
                            <td className="border-2 p-2">{item.title}</td>
                            <td className="border-2 p-2" style={styles.td}>
                                <button onClick={() => {
                                    setNews(item);
                                    setUpdatedEn(true)
                                    handleOpen()
                                }}
                                >
                                    <EditIcon />
                                </button>
                            </td>
                            <td className="border-2 p-2" style={styles.td}>
                                <button onClick={() => {
                                    setNews(item);
                                    setUpdatedEn(false)
                                    handleOpen()
                                }}
                                >
                                    <EditIcon />
                                </button>
                            </td>
                            <td className="border-2 p-2" style={styles.td}>
                                <input type="checkbox" style={{ width: 20, height: 20 }} defaultChecked={item.hidden} onChange={async event => {
                                    const form = { hidden: !(item.hidden) }
                                    customAxios.put(`/kis-news/${item.id}`, form)
                                        .then((res) => {
                                            setErr(res.data)
                                        })
                                        .catch((err) => {
                                            // setErr({ message: 'No connection!', status: err.status })
                                            console.log(err);
                                            setErr(err)
                                        })
                                }} />
                            </td>
                            <td className="border-2 p-2" style={styles.td}>
                                <button onClick={() => {
                                    deleteNews(item.id)
                                }}>
                                    <DeleteIcon />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}