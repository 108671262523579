import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import customAxios from '../../../../components/api/customaxios';


/*
 * Example Parchment format from
 * https://quilljs.com/guides/cloning-medium-with-parchment/
 * See the video example in the guide for a complex format
 */

/*
 * Editor component with default and custom formats
 */

export function CreateCustomers(props) {

    const [form, setForm] = useState({
        name: '',
        imgFile: ''
    })
    const [err, setErr] = useState({
        message: '',
        status: 200
    })

    const onChangeCompanyName = (e) => {
        setForm({ ...form, name: e.target.value })
    }

    const onChangeImg = (e) => {
        setForm({ ...form, imgFile: e.target.files[0] })
    }

    const createCustomer = () => {
        customAxios.post('/customers', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {
                setErr({ message: res.data.message, status: res.data.status })
            })
            .catch((err) => {
                setErr({ message: 'No connection!', status: err.status })
                console.log(err);
            })
    }

    return (
        <>
            <div className='flex flex-col h-[80vh] pb-1'>
                <div className='w-[100%] text-center text-white bg-[#094276] text-2xl rounded-t-2xl px-2 py-3'>
                    Create Customers
                </div>
                <div className='px-[5%] py-[3%] overflow-auto grow w-[100%]'>
                    <div>Company Name:</div>
                    <textarea
                        type="text"
                        className='border rounded-2xl w-[100%] p-2 h-auto'
                        value={form.name}
                        onChange={onChangeCompanyName}
                    />

                    <div>Image:</div><br />
                    <input type="file" onChange={onChangeImg} />
                    <br />

                    <div className={err.status !== 200 ? 'text-red-500' : 'text-green-500'}>{err.message}</div>

                    <button className='rounded-2xl py-1 px-3 my-4 bg-[#094276] text-white'
                        onClick={createCustomer}>
                        Create Customer
                    </button>
                </div>
            </div>
        </>
    );
}