// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import newsReducer from './newsSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, newsReducer);

export const store = configureStore({
  reducer: {
    news: persistedReducer,
  },
});

export const persistor = persistStore(store);