import { useEffect } from "react";
import { useState } from "react";
import customAxios from "../../../components/api/customaxios";
import '../../../components/home/home.css'
import { LANGUAGES, checkJapaneseWithParam } from "../../../utils";
import React from 'react'
import { Edit } from "./Edit";


export function CareerManagement(props) {
    const [isLoading, setIsLoading] = useState(true)
    const [careers, setCareers] = useState([])
    const [lang, setLang] = useState(LANGUAGES.EN)
    const [careerIndex, setCareerIndex] = useState(0)
    const [reload, setReload] = useState(false)
    const [career, setCareer] = useState({
        id: -1,
        title: "",
        titleJp: "",
        jd: "",
        jdJp: "",
        jr: "",
        jrJp: "",
        compensation: "",
        compensationJp: "",
        createdAt: '',
        updatedAt: ''
    })
    const [edit, setEdit] = useState(false)

    useEffect(() => {
        customAxios.get('/career')
            .then(res => {
                setCareers(res.data)
                setCareer((res.data)[careerIndex])
                setIsLoading(false)
                setReload(false)
            })
            .catch(err => {
                console.log(err);
            })
    }, [reload, careerIndex, lang])

    return isLoading
        ? (
            <>Loading, please wait...</>
        )
        : (
            <div className="flex-auto">
                <div className="flex" style={{ width: '100%' }}>
                    <div className="flex-1">
                        <div className="flex mb-10 border shadow-md w-fit p-2 mt-20">
                            <span>Choose Career: </span>
                            <select onChange={event => {
                                setCareer(careers[event.target.value])
                                setCareerIndex(event.target.value)
                                setEdit(false)
                            }}>
                                {careers.map((career, index) => (
                                    <option key={index} defaultValue={careerIndex === index} value={index}>{index + 1}</option>
                                ))}
                            </select>&nbsp;
                            <span>Choose Language: </span>
                            <select onChange={event => {
                                setLang(event.target.value)
                                setEdit(false)
                            }}>
                                <option value={LANGUAGES.EN}>English</option>
                                <option value={LANGUAGES.JP}>Japanese</option>
                            </select>&nbsp;
                            <button onClick={() => {
                                setEdit(true)
                            }}>
                                Choose
                            </button>
                        </div>
                        {edit && (<Edit lang={lang} career={career} setCareer={setCareer} setReload={setReload} />)}
                    </div>
                    <div key={career.id} className="rounded-2xl p-[5%] my-[5%] flex-1"
                        style={{ boxShadow: '0px 4px 50px rgba(179.56, 179.56, 179.56, 0.25)' }}>
                        <div className="text-2xl font-bold mb-[3%]" style={{ fontFamily: 'Playfair Display' }}>
                            {checkJapaneseWithParam(lang) ? career.titleJp : career.title}
                        </div>
                        <div className='career sm:table border-spacing-1 border border-separate border-white text-xs sm:text-sm'
                            style={{ fontSize: 13, lineHeight: 1.2 }}>
                            <div className="sm:table-row-group">
                                <div className="sm:table-row">
                                    <div className='sm:table-cell border border-white flex careers-start sm:mt-0 mt-[10vw]'>Job Description:</div>
                                    <div className='sm:table-cell border border-white font-light'>
                                        <div dangerouslySetInnerHTML={{ __html: checkJapaneseWithParam(lang) ? career.jdJp : career.jd }} />
                                    </div>
                                </div>
                                <div className="sm:table-row">
                                    <div className='sm:table-cell border border-white flex careers-start sm:mt-0 mt-[10vw]'>Job requirement:</div>
                                    <div className='sm:table-cell border border-white font-light'>
                                        <div dangerouslySetInnerHTML={{ __html: checkJapaneseWithParam(lang) ? career.jrJp : career.jr }} />
                                    </div>
                                </div>
                                <div className="sm:table-row">
                                    <div className='sm:table-cell border border-white flex careers-start sm:mt-0 mt-[10vw]'>Compensation and Benefits:</div>
                                    <div className='sm:table-cell border border-white font-light'>
                                        <div dangerouslySetInnerHTML={{ __html: checkJapaneseWithParam(lang) ? career.compensationJp : career.compensation }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
}