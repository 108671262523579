import React, { useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import customAxios from "../../../../components/api/customaxios";
import { CreateCustomers } from "./CreateCustomer";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    height: '80vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    borderRadius: '1rem'
};

const styles = {
    td: {
        textAlign: 'center'
    }
}

export function CustomersManagement() {
    const [customersList, setCustomersList] = useState([])
    const [open, setOpen] = React.useState(false);
    const [err, setErr] = useState({
        message: '',
        status: 200
    })

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        customAxios.get('/customers')
            .then(res => {
                setCustomersList(res.data)
            })
            .catch(err => {
                console.log(err);
                setErr(err)
            })
    }, [open, err])

    function deleteNews(customerId) {
        customAxios.delete(`/customers/${customerId}`)
            .then(res => {
                setErr(res.data)
            })
            .catch(err => {
                console.log(err);
            })
    }


    return (
        <div className="mx-20 mt-10 mb-20">
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, overscrollBehavior: 'auto' }}>
                    <CreateCustomers />
                </Box>
            </Modal>
            <div className="text-2xl font-extrabold uppercase">Customers Management</div>
            <button
                onClick={() => {
                    handleOpen()
                }}
                className="rounded-2xl py-1 px-3 my-4 bg-[#094276] text-white"
            >Create customer
            </button>
            <div className={err.status !== 200 ? 'text-red-500' : 'text-green-500'}>{err.message}</div>

            <table className="table-auto border-2">
                <thead className="text-white bg-[#094276]">
                    <tr className="border-2">
                        <th className="border-2 p-2">Name</th>
                        <th className="border-2 p-2">Image</th>
                        <th className="border-2 p-2">Hidden</th>
                        <th className="border-2 p-2">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {customersList.map((item) => (
                        <tr className="border-2" key={item.id}>
                            <td className="border-2 p-2">{item.name}</td>
                            <td className="border-2 p-2" style={styles.td}>
                                <img 
                                src={`data:image/${item.imgType};base64,${item.imgData}`} 
                                alt={item.name} 
                                style={{maxHeight: 70}} />
                            </td>
                            <td className="border-2 p-2" style={styles.td}>
                                <input type="checkbox" 
                                style={{ width: 20, height: 20 }} 
                                defaultChecked={item.hidden} onChange={async () => {
                                    const form = { hidden: !(item.hidden) }
                                    customAxios.put(`/customers/${item.id}`, form)
                                        .then((res) => {
                                            setErr(res.data)
                                        })
                                        .catch((err) => {
                                            // setErr({ message: 'No connection!', status: err.status })
                                            console.log(err);
                                            setErr(err)
                                        })
                                }} />
                            </td>
                            <td className="border-2 p-2" style={styles.td}>
                                <button onClick={() => {
                                    deleteNews(item.id)
                                }}>
                                    <DeleteIcon />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}