import { useState } from "react";
import { checkJapaneseWithParam } from "../../../utils";
import ReactQuill from "react-quill";
import customAxios from "../../../components/api/customaxios";

const styles = {
    reactQuill: {
        fontFamily: 'Lexend Deca'
    },
    save: {
        // back
    }
};

export function EditOffice(props) {
    const lang = props.lang
    const office = props.office
    const setOffice = props.setOffice
    const setReload = props.setReload
    const setEditOffice = props.setEditOffice
    const [err, setErr] = useState({
        message: '',
        status: 200
    })

    const onUpdate = () => {
        customAxios.put(`/office/${office.id}`, office)
            .then(res => setErr(res.data))
            .catch(e => console.log(e))
        setReload(true)
    }

    const onCancel = () => {
        setEditOffice(false)
    }

    return (
        <>
            <div style={{margin: '5px 0'}}>
                <span>Name:</span>
                <input
                    type="text"
                    style={{ width: '22vw' }}
                    value={!checkJapaneseWithParam(lang) ? office.name : office.nameJp}
                    onChange={e => {
                        if (checkJapaneseWithParam(lang)) {
                            setOffice({ ...office, nameJp: e.target.value })
                        } else {
                            setOffice({ ...office, name: e.target.value })
                        }
                    }}
                />
            </div>
            <div style={{margin: '5px 0'}}>
                <span>Company Name:</span>
                <input
                    type="text"
                    style={{ width: '20vw' }}
                    value={!checkJapaneseWithParam(lang) ? office.companyName : office.companyNameJp}
                    onChange={e => {
                        if (checkJapaneseWithParam(lang)) {
                            setOffice({ ...office, companyNameJp: e.target.value })
                        } else {
                            setOffice({ ...office, companyName: e.target.value })
                        }
                    }}
                />
            </div>
            <div style={{margin: '5px 0'}}>
                <span>Established:</span>
                <input
                    type="text"
                    style={{ width: '20vw' }}
                    value={!checkJapaneseWithParam(lang) ? office.established : office.establishedJp}
                    onChange={e => {
                        if (checkJapaneseWithParam(lang)) {
                            setOffice({ ...office, establishedJp: e.target.value })
                        } else {
                            setOffice({ ...office, established: e.target.value })
                        }
                    }}
                />
            </div>
            <div>President:</div>
            <ReactQuill theme="snow"
                id="president"
                // modules={modules}
                value={checkJapaneseWithParam(lang) ? office.presidentJp : office.president}
                onChange={newContent => {
                    // if (newContent === currentContent) return;
                    if (checkJapaneseWithParam(lang)) {
                        setOffice({ ...office, presidentJp: newContent })
                    } else {
                        setOffice({ ...office, president: newContent })
                    }
                }}
                style={styles.reactQuill}
            />
            <div style={{margin: '5px 0'}}>
                <span>Register Capital:</span>
                <input
                    type="text"
                    style={{ width: '20vw' }}
                    value={!checkJapaneseWithParam(lang) ? office.registerCapital : office.registerCapitalJp}
                    onChange={e => {
                        if (checkJapaneseWithParam(lang)) {
                            setOffice({ ...office, registerCapitalJp: e.target.value })
                        } else {
                            setOffice({ ...office, registerCapital: e.target.value })
                        }
                    }}
                />
            </div>
            <div>Place:</div>
            <ReactQuill theme="snow"
                id="place"
                // modules={modules}
                value={checkJapaneseWithParam(lang) ? office.placeJp : office.place}
                onChange={newContent => {
                    // if (newContent === currentContent) return;
                    if (checkJapaneseWithParam(lang)) {
                        setOffice({ ...office, placeJp: newContent })
                    } else {
                        setOffice({ ...office, place: newContent })
                    }
                }}
                style={styles.reactQuill}
            />
            <div style={{margin: '5px 0'}}>
                <span>Phone:</span>
                <input
                    type="text"
                    style={{ width: '20vw' }}
                    value={office.phone}
                    onChange={e => {
                        setOffice({ ...office, phone: e.target.value })
                    }}
                />
            </div>
            <div style={{margin: '5px 0'}}>
                <span>Website:</span>
                <input
                    type="text"
                    style={{ width: '20vw' }}
                    value={office.website}
                    onChange={e => {
                        setOffice({ ...office, website: e.target.value })
                    }}
                />
            </div>
            <div style={{margin: '5px 0'}}>
                <span>Email:</span>
                <input
                    type="text"
                    style={{ width: '20vw' }}
                    value={office.email}
                    onChange={e => {
                        setOffice({ ...office, email: e.target.value })
                    }}
                />
            </div>
            <div>Created At: {office.createdAt}</div>
            <div>Updated At: {office.updatedAt}</div>
            <button
                style={{ backgroundColor: '#094276', color: 'white' }}
                onClick={onUpdate}>
                Save
            </button>
            <button
                style={{ backgroundColor: '#094276', color: 'white' }}
                onClick={onCancel}>
                Cancel
            </button>
            <div className={err.status !== 200 ? 'text-red-500' : 'text-green-500'}>{err.message}</div>
        </>
    )
}