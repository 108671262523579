import { useState } from "react";
import { checkJapaneseWithParam } from "../../../utils";
import ReactQuill from "react-quill";
import customAxios from "../../../components/api/customaxios";

const styles = {
    reactQuill: {
        fontFamily: 'Lexend Deca'
    },
    save: {
        // back
    }
};

export function Edit(props) {
    const lang = props.lang
    const career = props.career
    const setCareer = props.setCareer
    const setReload = props.setReload
    const [editJd, setEditJd] = useState(false);
    const [editJr, setEditJr] = useState(false);
    const [editCompen, setEditCompen] = useState(false);
    const [err, setErr] = useState({
        message: '',
        status: 200
    })

    function openJd() {
        setEditJd(true)
        setEditJr(false)
        setEditCompen(false)
    }

    function openJr() {
        setEditJd(false)
        setEditJr(true)
        setEditCompen(false)
    }

    function openCompen() {
        setEditJd(false)
        setEditJr(false)
        setEditCompen(true)
    }


    return (
        <>
            <div>
                <span>Title:</span>
                <input
                    type="text"
                    style={{ width: '22vw' }}
                    value={!checkJapaneseWithParam(lang) ? career.title : career.titleJp}
                    onChange={e => {
                        if (checkJapaneseWithParam(lang)) {
                            setCareer({ ...career, titleJp: e.target.value })
                        } else {
                            setCareer({ ...career, title: e.target.value })
                        }
                    }}
                />
            </div>
            <div>Job Description:<button onClick={openJd}> Edit</button></div> 
            {editJd && (
                <ReactQuill theme="snow"
                    id="president"
                    // modules={modules}
                    value={checkJapaneseWithParam(lang) ? career.jdJp : career.jd}
                    onChange={newContent => {
                        // if (newContent === currentContent) return;
                        if (checkJapaneseWithParam(lang)) {
                            setCareer({ ...career, jdJp: newContent })
                        } else {
                            setCareer({ ...career, jd: newContent })
                        }
                    }}
                    style={styles.reactQuill}
                />
            )}

            <div>Job requirement: <button onClick={openJr}> Edit</button></div>
            {editJr && (
                <ReactQuill theme="snow"
                    id="president"
                    // modules={modules}
                    value={checkJapaneseWithParam(lang) ? career.jrJp : career.jr}
                    onChange={newContent => {
                        // if (newContent === currentContent) return;
                        if (checkJapaneseWithParam(lang)) {
                            setCareer({ ...career, jrJp: newContent })
                        } else {
                            setCareer({ ...career, jr: newContent })
                        }
                    }}
                    style={styles.reactQuill}
                />
            )}
            <div>Compensation and Benefits:<button onClick={openCompen}> Edit</button></div> 
            {editCompen && (
                <ReactQuill theme="snow"
                    id="president"
                    // modules={modules}
                    value={checkJapaneseWithParam(lang) ? career.compensationJp : career.compensation}
                    onChange={newContent => {
                        // if (newContent === currentContent) return;
                        if (checkJapaneseWithParam(lang)) {
                            setCareer({ ...career, compensationJp: newContent })
                        } else {
                            setCareer({ ...career, compensation: newContent })
                        }
                    }}
                    style={styles.reactQuill}
                />
            )}

            <div>Created At: {career.createdAt}</div>
            <div>Updated At: {career.updatedAt}</div>
            <button
                style={{ backgroundColor: '#094276', color: 'white' }}
                onClick={() => {
                    customAxios.put(`/career/${career.id}`, career)
                        .then(res => setErr(res.data))
                        .catch(e => console.log(e))
                    setReload(true)
                }}>
                Save
            </button>
            <div className={err.status !== 200 ? 'text-red-500' : 'text-green-500'}>{err.message}</div>
        </>
    )
}