import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import './home.css'
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import React from 'react'


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import customAxios from '../api/customaxios';
import { checkiflessthan10 } from '../../utils';

export function Project() {
    const [projects, setProjects] = useState([]);
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        customAxios.get('/projects')
            .then((res) => {
                setProjects(res.data)
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err);
            })
    }, [t])

    return (

        isLoading ? (
            <>Loading, please wait...</>
        ) : (
            <div className='mx-[10%] my-[5vw] bg-[#16344B] text-white rounded-2xl'
                style={{ boxShadow: '4px 4px 29px 6px rgba(0, 0, 0, 0.25)', margin: '0 5vw' }}>
                <Swiper navigation={true} modules={[Navigation]} className="mySwiper project rounded-2xl">
                    {projects.map((item, index) => (
                        <SwiperSlide className='' key={index}>
                            <div className='flex flex-wrap rounded-lg' style={{ margin: '5vw 7vw 1vw 7vw' }}>
                                <div className='flex-1'>
                                    <p className='font-black text-[#3F6E93] w-fit pl-6 pr-4 py-2'
                                        style={{ boxShadow: '0px 0px 7px 3px rgba(255, 255, 255, 0.25) inset', borderRadius: 40, border: '1px #85C5FF solid', letterSpacing: 10 }}>
                                        <span className='text-white'>{checkiflessthan10(index + 1)}</span>/{checkiflessthan10(projects.length)}
                                    </p>
                                    <div className='font-extrabold text-lg sm:text-2xl my-[5%]' style={{ fontFamily: 'Playfair Display' }}>
                                        {localStorage.getItem('i18nextLng') !== 'jp' ? item.title : item.titleJp}
                                    </div>

                                </div>
                                <div className='flex-1 text-left sm:text-right mb-[2%] sm:mb-0 mt-[2%]'>
                                    {localStorage.getItem('i18nextLng') !== 'jp' ?
                                        <>
                                            These are some of <span className='uppercase font-black text-base'>outstanding projects</span>.
                                        </>
                                        :
                                        <>
                                            これらはいくつかの<span className='uppercase font-black text-base'>典型的なプロジェクト</span>です.
                                        </>}

                                </div>
                            </div>
                            <div className='font-light rounded-lg mb-[5%] px-[7%] pb-[1%]'>
                                <div dangerouslySetInnerHTML={{ __html: localStorage.getItem('i18nextLng') !== 'jp' ? item.content : item.contentJp }}></div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        )
    )
}