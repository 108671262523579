import { useEffect, useState } from 'react'
import HeroImg from '../assets/career.png'
import customAxios from '../components/api/customaxios'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { checkJapaneseInStorage } from '../utils'
export function Career() {
    const { t } = useTranslation();
    const [careers, setCareers] = useState([])
    useEffect(() => {
        customAxios.get('/career')
            .then((res) => {
                setCareers(res.data)
            })
            .catch((err) => console.log(err))
    }, [])

    return (
        <div>
            <div className='bg-bottom bg-cover min-h-[50vh] sm:min-h-[37.08vw]' style={{ backgroundImage: `url(${HeroImg})` }}>
                <div className='text-center text-white w-[70%] mx-[15%]'>
                    <div className='text-xl sm:text-6xl font-extrabold py-[5vw] sm:pt-[5vw]'>
                        {t('career.boostYourCareer')} <br />
                        {t('career.weAreHereToHelp')}</div>
                    <div className='text-[10px] leading-3 sm:leading-6 sm:text-[1.1vw]'>
                        {t('career.excitedToWorkWithYou')} <br />
                        {t('career.checkVacancy')}
                    </div>
                </div>
            </div>
            <div className="m-[10%]">
                <div className="flex flex-wrap mb-[10%]">
                    <div className="sm:w-[45%]">
                        <div className="text-base">
                            {t('career.welcome')}
                        </div>
                        <div className="text-4xl font-extrabold" style={{ fontFamily: 'Playfair Display' }}>
                            {t('career.weAreHiring')}
                        </div>
                    </div>
                    <div className='flex-1 flex items-end font-light'>
                        <div className=' text-left my-[10%] sm:my-0 sm:text-right w-[100%]'>
                            {t('career.teamOfLifelongLearners')}
                        </div>
                    </div>
                </div>
                {careers.map((item) => (
                    <div key={item.id} className="rounded-2xl p-[10%] my-[5%]" style={{ boxShadow: '0px 4px 50px rgba(179.56, 179.56, 179.56, 0.25)' }}>
                        <div className="text-2xl font-bold mb-[3%]" style={{ fontFamily: 'Playfair Display' }}>
                            {checkJapaneseInStorage() ? item.titleJp : item.title}
                        </div>
                        <div className='career sm:table border-spacing-1 border border-separate border-white text-xs sm:text-sm'>
                            <div className="sm:table-row-group">
                                <div className="sm:table-row">
                                    <div className='sm:table-cell border border-white flex items-start sm:mt-0 sm:pr-5 mt-[10vw]'>Job Description:</div>
                                    <div className='sm:table-cell border border-white font-light'>
                                        <div dangerouslySetInnerHTML={{ __html: checkJapaneseInStorage() ? item.jdJp : item.jd }} />
                                    </div>
                                </div>
                                <div className="sm:table-row">
                                    <div className='sm:table-cell border border-white flex items-start sm:mt-0 sm:pr-5 mt-[10vw]'>Job requirement:</div>
                                    <div className='sm:table-cell border border-white font-light'>
                                        <div dangerouslySetInnerHTML={{ __html: checkJapaneseInStorage() ? item.jrJp : item.jr }} />
                                    </div>
                                </div>
                                <div className="sm:table-row">
                                    <div className='sm:table-cell border border-white flex items-start sm:mt-0 sm:pr-5 mt-[10vw]'>Compensation and Benefits:</div>
                                    <div className='sm:table-cell border border-white font-light'>
                                        <div dangerouslySetInnerHTML={{ __html: checkJapaneseInStorage() ? item.compensationJp : item.compensation }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}