// src/redux/newsSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const newsSlice = createSlice({
  name: 'news',
  initialState: {
    openNews: false,
  },
  reducers: {
    setOpenNews: (state, action) => {
      state.openNews = action.payload;
    },
  },
});

export const { setOpenNews } = newsSlice.actions;

export const selectOpenNews = (state) => state.news.openNews;

export default newsSlice.reducer;