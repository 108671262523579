import * as React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import WorkIcon from '@mui/icons-material/Work';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import LogoutIcon from '@mui/icons-material/Logout';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { NewsManagement } from './admin/NewsManagement';
import { CareerManagement } from './admin/career/CareerManagement';
import './admin/Admin.css'
import { HomeManagement } from './admin/home/HomeManagement';
import { AboutManagement } from './admin/about/AboutManagement';
import { ServiceManagement } from './admin/service/ServiceManagement';
import { useEffect } from 'react';
import axios from 'axios';
import BASE_URL from '../apiConfig';

const pages = [
    {
        icon: <HomeIcon />,
        title: 'Home',
        url: 'home'
    },
    {
        icon: <InfoIcon />,
        title: 'About',
        url: 'about'
    },
    {
        icon: <HomeRepairServiceIcon />,
        title: 'Services',
        url: 'services'
    },
    {
        icon: <WorkIcon />,
        title: 'Careers',
        url: 'careers'
    },
    {
        icon: <NewspaperIcon />,
        title: 'News',
        url: 'news'
    }
]



export default function Admin() {
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        axios
            .get(`${BASE_URL}/user/check-login`,{
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }  
            })
            .then(() => {
            })
            .catch((err) => {
                console.log(err);
                navigate('/login') 
            })// eslint-disable-next-line
    }, []);

    return (
        <div className={'admin flex ' + (location.pathname.startsWith('/admin') ? '' : 'hidden')}>
            <div className="sticky top-0 left-0 w-[15vw] h-[100vh] flex flex-col justify-center items-center bg-gray-100">
                {pages.map((item, index) => (
                    <div key={index}
                        onClick={() => navigate(item.url)}
                        className={"flex items-center py-2 px-4 w-[80%] "
                            + (location.pathname.includes(item.url) ? 'bg-blue-200 hover:bg-blue-400' : 'hover:bg-blue-100')
                        }>
                        {item.icon} {item.title}
                    </div>
                ))}

                <div
                    onClick={() => {
                        localStorage.removeItem('token')
                        window.location.href = '/login'
                    }}
                    className="flex items-center py-2 my-3 px-4 w-[80%] rounded-xl hover:bg-blue-100"
                >
                    <LogoutIcon />  Logout
                </div>
            </div>
            <Routes>
                <Route index path='home' element={<HomeManagement />} />
                <Route path="about" element={<AboutManagement />} />
                <Route path="services" element={<ServiceManagement />} />
                <Route path="careers" element={<CareerManagement />} />
                <Route path="news" element={<NewsManagement />} />
            </Routes>
        </div>
    );
}