import { useEffect } from "react";
import { useState } from "react";
import customAxios from "../../../../components/api/customaxios";
import '../../../../components/home/home.css'
import { LANGUAGES, checkiflessthan10, showValueBasedOnLang } from "../../../../utils";
import React from 'react'


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { EditProject } from "./EditProject";
import { CreateProject } from "./CreateProject";


export function Projects(props) {
    const [isLoading, setIsLoading] = useState(true)
    const [projectsList, setProjectsList] = useState([])
    const [lang, setLang] = useState(LANGUAGES.EN)
    const [reload, setReload] = useState(false)
    const [projectIndex, setProjectIndex] = useState(0)
    const [project, setProject] = useState({
        id: undefined,
        title: '',
        titleJp: '',
        content: '',
        contentJp: ''
    })
    const [openEdit, setOpenEdit] = useState(false)
    const [openCreate, setOpenCreate] = useState(false)

    useEffect(() => {
        customAxios.get('/projects')
            .then(res => {
                setProjectsList(res.data)
                setProject((res.data)[projectIndex])
                setIsLoading(false)
                setReload(false)
            })
            .catch(err => {
                console.log(err);
            })// eslint-disable-next-line
    }, [reload])

    return isLoading
        ? (
            <>Loading, please wait...</>
        )
        : (
            <div className="flex-auto">
                <div className="flex" style={{ width: '100%' }}>
                    <div className="flex-1">

                        {!openCreate && !openEdit && (
                            <div className="flex mb-10 border shadow-md w-fit p-2">
                                <span>Choose Project: </span>
                                {projectsList.length === 0 ? (
                                    <>No project</>
                                ) : (
                                    <select onChange={event => {
                                        setProject(projectsList[event.target.value])
                                        setProjectIndex(event.target.value)
                                        console.log('projectsList[event.target.value]', projectsList[event.target.value]);
                                    }}>
                                        <option defaultValue={true}>Choose</option>
                                        {projectsList.map((project, index) => (
                                            <option key={index} value={index}>{index + 1}</option>
                                        ))}
                                    </select>
                                )}
                                &nbsp;
                                <span>Choose Language: </span>
                                <select onChange={event => {
                                    setLang(event.target.value)
                                    setOpenEdit(false)
                                }}>
                                    <option>Choose</option>
                                    <option value={LANGUAGES.EN}>English</option>
                                    <option value={LANGUAGES.JP}>Japanese</option>
                                </select>&nbsp;
                                <button onClick={async () => {
                                    console.log('projectIndex', projectIndex);
                                    setOpenEdit(true)
                                }}>
                                    Choose
                                </button>
                            </div>
                        )}


                        {!openEdit && !openCreate && (
                            <div className="flex mb-10 border shadow-md w-fit p-2">

                                <button onClick={async () => {
                                    setOpenCreate(true)
                                    setProjectIndex(projectsList.length)
                                    const newProject = {
                                        title: '',
                                        content: ''
                                    }
                                    setProject(newProject)
                                    var addProjects = projectsList
                                    addProjects.push(newProject)
                                    setProjectsList(addProjects)
                                }}>
                                    Create new project
                                </button>
                                <div style={{ color: 'red', margin: '0 5px' }}>WARNING: This edit or create will also affect projects in outsourcing services part</div>
                            </div>
                        )}



                        {openEdit && (
                            <EditProject
                                lang={lang}
                                project={project}
                                setProject={setProject}
                                setReload={setReload}
                                setOpen={setOpenEdit}
                                projectsList={projectsList}
                                setProjectsList={setProjectsList}
                                projectIndex={projectIndex}
                            />
                        )}

                        {openCreate && (
                            <CreateProject
                                projectIndex={projectIndex}
                                project={project}
                                setProject={setProject}
                                projectsList={projectsList}
                                setProjectsList={setProjectsList}
                                setReload={setReload}
                                setOpenCreate={setOpenCreate}
                            />
                        )}
                    </div>
                    {(openCreate || openEdit) && (
                        <div className="flex-1 admin">
                            {projectsList.length > 0 && (
                                <div className='bg-[#16344B] text-white rounded-2xl'
                                    style={{
                                        boxShadow: '4px 4px 29px 6px rgba(0, 0, 0, 0.25)',
                                        margin: '20px 10px',
                                        padding: '10px',
                                        width: '40vw',
                                        textAlign: 'left'
                                    }}>
                                    <div className="mySwiper project rounded-2xl">
                                        <div className='flex flex-wrap rounded-lg'
                                            style={{ margin: '5% 7% 1% 7%' }}
                                        >
                                            <div className='flex-1'>
                                                <p className='font-black text-[#3F6E93] w-fit pl-6 pr-4 py-2'
                                                    style={{ boxShadow: '0px 0px 7px 3px rgba(255, 255, 255, 0.25) inset', borderRadius: 40, border: '1px #85C5FF solid', letterSpacing: 10 }}>
                                                    <span className='text-white'>{checkiflessthan10(parseInt(projectIndex) + 1)}</span>/{checkiflessthan10(projectsList.length)}
                                                </p>
                                                <div className='font-extrabold text-lg sm:text-2xl my-[5%]' style={{ fontFamily: 'Playfair Display' }}>
                                                    {showValueBasedOnLang(lang, project, 'title')}
                                                </div>

                                            </div>
                                            <div className='flex-1 text-left sm:text-right mb-[2%] sm:mb-0 mt-[2%]'>These are some of <span className='uppercase font-black text-base'>outstanding projects</span> .</div>
                                        </div>
                                        <div className='font-light rounded-lg mb-[5%] px-[7%] pb-[1%]'>
                                            <div dangerouslySetInnerHTML={{ __html: showValueBasedOnLang(lang, project, 'content') }}></div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    )}

                </div>
            </div>
        )
}