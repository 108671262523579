import { useState } from "react"
import React from 'react'
import { OfficesManagement } from "./OfficesManagement"

const styles = {
    button: {
        margin: 10,
        padding: 10,
        color: '#094276',
        borderRadius: '1rem'
    }
}

export function AboutManagement() {
    const [openOffice, setOpenOffice] = useState(false)

    return (
        <>
            <div className="pl-10 py-10 w-[90vw]">
                <div style={{ width: '100%' }}>
                    <button
                        style={{
                            ...styles.button,
                            backgroundColor: (openOffice ? 'rgb(243 244 246)' : 'rgb(209 213 219)')
                        }}
                        onClick={() => {
                            setOpenOffice(true)
                        }}
                    >
                        Offices Management
                    </button>
                </div>
                {openOffice && <OfficesManagement />}
            </div>

        </>
    )
}