import { ServiceHero } from "../components/outsource/Hero";
import { IntroSerice } from "../components/outsource/IntroService";
import { Project } from "../components/outsource/Project";
import React from 'react'

export function OutSourcing() {
    return (
        <div>
            <ServiceHero />
            <IntroSerice />
            <Project />
        </div>
    )
}