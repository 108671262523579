import KisVN from '../../assets/about/kisvn.png'
import KisJp from '../../assets/about/kisjapan.png'
import { useEffect, useState } from 'react'
import customAxios from '../api/customaxios'
import { useTranslation } from 'react-i18next'

export function Offices() {
    const [offices, setOffices] = useState([])
    const { t } = useTranslation()

    useEffect(() => {
        customAxios.get('/office')
            .then(res => setOffices(res.data))
            .catch(err => console.log(err))
    }, [])

    function checkisJapan() {
        return localStorage.getItem('i18nextLng') === 'jp'
    }

    return (
        <div className="m-[10%]">
            <div className="bg-white flex flex-wrap sm:flex-nowrap  rounded-2xl my-[10%]" style={{ boxShadow: '0px 4px 50px rgba(179.56, 179.56, 179.56, 0.25)' }}>
                <img src={KisVN} alt="KisVN" className='rounded-t-2xl sm:rounded-tr-none sm:rounded-l-2xl sm:w-[50%]' />
                <div className='p-[5%]'>
                    <div className='font-extrabold text-lg sm:text-[1.6vw]' style={{ fontFamily: 'Playfair Display' }}>
                        {t('about.ourOffices')}
                    </div>
                    <div className='sm:text-[1.3vw] text-sm font-semibold my-[5%]'>
                        {checkisJapan() ? offices[0]?.nameJp : offices[0]?.name}
                    </div>
                    <div className='sm:table border-spacing-1 border border-separate border-white text-xs sm:text-[0.9vw]'>
                        <div className='sm:table-row sm:mb-0 mb-[8vw]-group'>
                            <div className='sm:table-row sm:mb-0 mb-[8vw]'>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white'>{t('about.companyName')}</div>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white font-light'>
                                    {checkisJapan() ? offices[0]?.companyNameJp : offices[0]?.companyName}
                                </div>
                            </div>
                            <div className='sm:table-row sm:mb-0 mb-[8vw]'>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white '>{t('about.establishedDay')}</div>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white font-light'>
                                    {checkisJapan() ? offices[0]?.establishedJp : offices[0]?.established}
                                </div>
                            </div>
                            <div className='sm:table-row sm:mb-0 mb-[8vw]'>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white flex items-start '>{t('about.presidentCEO')}</div>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white font-light'>
                                    <div dangerouslySetInnerHTML={{ __html: checkisJapan() ? offices[0]?.presidentJp : offices[0]?.president }} />
                                </div>
                            </div>
                            <div className='sm:table-row sm:mb-0 mb-[8vw]'>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white flex items-start '>{t('about.registeredCapital')}</div>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white font-light'>
                                    {checkisJapan() ? offices[0]?.registerCapitalJp : offices[0]?.registerCapital}
                                </div>
                            </div>
                            <div className='sm:table-row sm:mb-0 mb-[8vw]'>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white flex items-start '>{t('about.place')}</div>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white font-light'>
                                    <div dangerouslySetInnerHTML={{ __html: checkisJapan() ? offices[0]?.placeJp : offices[0]?.place }} />
                                </div>
                            </div>
                            <div className='sm:table-row sm:mb-0 mb-[8vw]'>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white flex items-start '>{t('about.phone')}</div>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white font-light'>
                                    {offices[0]?.phone}
                                </div>
                            </div>
                            <div className='sm:table-row sm:mb-0 mb-[8vw]'>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white flex items-start '>{t('about.website')}</div>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white font-light'>
                                    {offices[0]?.website}
                                </div>
                            </div>
                            <div className='sm:table-row sm:mb-0 mb-[8vw]'>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white flex items-start '>{t('about.place')}</div>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white font-light'>
                                    {offices[0]?.email}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white flex flex-wrap sm:flex-nowrap rounded-2xl" style={{ boxShadow: '0px 4px 50px rgba(179.56, 179.56, 179.56, 0.25)' }}>
                <img src={KisJp} alt="KisVN" className='rounded-t-2xl sm:rounded-tr-none sm:rounded-l-2xl sm:w-[50%]' />

                <div className='py-[2%] px-[5%]'>
                    <div className='font-extrabold text-lg sm:text-[1.6vw]' style={{ fontFamily: 'Playfair Display' }}>
                        {t('about.ourOffices')}
                    </div>
                    <div className='sm:text-[1.2vw] font-semibold my-[5%]'>
                        {checkisJapan() ? offices[1]?.nameJp : offices[1]?.name}
                    </div>
                    <div className='sm:table border-spacing-3 border border-separate border-white text-xs sm:text-[1.1vw]'>
                        <div className='sm:table-row sm:mb-0 mb-[8vw]-group'>
                            <div className='sm:table-row sm:mb-0 mb-[8vw]'>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white'>会社名</div>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white font-light'>
                                    {checkisJapan() ? offices[1]?.companyNameJp : offices[1]?.companyName}
                                </div>
                            </div>
                            <div className='sm:table-row sm:mb-0 mb-[8vw]'>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white '>設立</div>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white font-light'>
                                    {checkisJapan() ? offices[1]?.establishedJp : offices[1]?.established}
                                </div>
                            </div>
                            <div className='sm:table-row sm:mb-0 mb-[8vw]'>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white flex items-start '>役員</div>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white font-light'>
                                    <div dangerouslySetInnerHTML={{ __html: checkisJapan() ? offices[1]?.presidentJp : offices[1]?.president }} />
                                </div>
                            </div>
                            <div className='sm:table-row sm:mb-0 mb-[8vw]'>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white flex items-start '>住所</div>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white font-light'>
                                <div dangerouslySetInnerHTML={{ __html: checkisJapan() ? offices[1]?.placeJp : offices[1]?.place }} />
                                </div>
                            </div>
                            <div className='sm:table-row sm:mb-0 mb-[8vw]'>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white flex items-start '>TEL</div>
                                <div className='sm:table-cell sm:leading-[1.2vw] p-[0.1vw] border border-white font-light'>
                                    {offices[1]?.phone}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}