import { ServiceHero } from "../components/outsource/Hero";
import { Swiper, SwiperSlide } from "swiper/react";
import NezaBiz1 from '../assets/services/nexabiz.webp'
import NezaBiz2 from '../assets/services/nexabiz2.webp'
import NezaBiz3 from '../assets/services/nexabiz3.webp'
import NezaBiz4 from '../assets/services/nexabiz4.webp'
import NezaBiz5 from '../assets/services/nexabiz5.webp'
import NezaBiz6 from '../assets/services/nexabiz6.webp'
import NezaBiz7 from '../assets/services/nexabiz7.webp'
import React from "react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/pagination';

import { Navigation, Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";

const nexabiz = [NezaBiz1, NezaBiz2, NezaBiz3, NezaBiz4, NezaBiz5, NezaBiz6, NezaBiz7]

export function Product() {
    const { t } = useTranslation()
    return (
        <>
            <ServiceHero />
            <div className="m-[10%]">
                <div className="flex flex-wrap items-end mb-[10%]">
                    <div className="">
                        <div className="font-light">{t('services.letUsIntro')} </div>
                        <div className="font-extrabold text-2xl sm:text-4xl uppercase" style={{ fontFamily: 'Playfair Display' }}>NexaBiz</div>
                    </div>
                    <div className="grow"></div>
                    <div className="text-left sm:text-right font-light my-[10%] sm:my-0 sm:max-w-[40vw]">
                        {t('services.introContent')}
                    </div>
                </div>
                {/* <div className="rounded-2xl">
                    <img src={ConsultImg} alt="OutSrc" className='rounded-t-2xl' />
                    {localStorage.getItem('i18nextLng') === 'en' || localStorage.getItem('i18nextLng') === null ? <div dangerouslySetInnerHTML={{ __html: intro[0]?.content }} /> : <div dangerouslySetInnerHTML={{ __html: intro[0]?.contentJp }} />}
                </div> */}
                <div className="flex flex-wrap items-end mb-[10%]">
                    <div className="flex-1 w-[80%] md:w-[30vw]">
                        <Swiper
                            spaceBetween={10}
                            navigation={true}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination, Navigation]}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                            }}
                            className="mySwiper"
                        >
                            {nexabiz.map((item, index) => (
                                <SwiperSlide className="">
                                    <img src={item} style={{ width: '70%', margin: '0 15% 30px 15%' }} alt={item + index} key={index} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div style={{ color: 'green' }}>
                            <a
                                target="_blank"
                                href="https://play.google.com/store/apps/details?id=com.kis_v.nexabiz&pli=1"
                                rel="noreferrer"
                            >
                                Download in CHPlay: &nbsp;NezaBiz
                            </a>
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col items-center justify-center p-10 shadow-xl rounded-2xl">
                        <div style={{ fontSize: 14 }}>
                            <ul style={{ listStyleType: 'disc' }}>
                                <li>
                                    <b>Digital Business Card Management:</b>
                                    Simplify and streamline the way you manage business cards. Digitize your collection, eliminating the hassle of physical cards and ensuring you never lose a valuable contact again.
                                </li>
                                <li>
                                    <b>Efficient Networking:</b> Exchange digital business cards effortlessly at events, meetings, or casual encounters. Our app facilitates quick and contactless sharing, making networking a breeze.
                                </li>
                                <li>
                                    <b>Business Matching Technology:</b> Unlock the power of intelligent Business Matching. Our app utilizes cutting-edge artificial intelligence to analyze and match your professional interests with others in your network. Say goodbye to generic connections – forge meaningful relationships with those who matter.
                                </li>
                                <li>
                                    <b>Customizable Profiles:</b> Tailor your digital business card to showcase your skills, expertise, and business details. Impress contacts with a professional and dynamic representation of yourself and your business.
                                </li>
                                <li>
                                    <b>Instant Follow-Up:</b> Seamlessly follow up with your new connections directly through the app. Send messages, schedule meetings, or share relevant information effortlessly to nurture your professional relationships.
                                </li>
                                <li>
                                    <b>Security and Privacy:</b> Rest easy knowing your contact information is secure. Our app prioritizes data privacy and ensures that your information is shared only with your consent.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}