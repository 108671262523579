import { useTranslation } from 'react-i18next'
import HeroImg from '../../assets/about/abouthero.png'
import React from 'react'

export function Hero() {
    const { t } = useTranslation()
    return (
        <div className='bg-bottom bg-cover min-h-[50vh] sm:min-h-[37.08vw] flex items-center' style={{ backgroundImage: `url(${HeroImg})` }}>
            <div className=' ml-[5%] sm:ml[10%] text-white w-[37%]'>
                <div className='text-xl sm:text-[3vw] font-extrabold py-[3vw] sm:pt-[11vw]'>{t('about.whoAreWe')}</div>
                <div className='text-[10px] leading-3 sm:leading-6 sm:text-[1.1vw]'>
                    {t('about.herocontent')}
                    <span className='inline-block font-bold'>
                        {t('about.heroTalker')}
                    </span>
                </div>
            </div>
        </div>
    )
}